@import '../../../../assets/scss/variables';
.rentals_overlay_holder{
    .MuiPaper-root{
        width: 90%;
        padding-right: 50px;
        background-color: $overlay-content-bg;
        .btn_circle_close{
            position: fixed;
            top: 25px;
            left: calc(10% - 61px);
            z-index: 1;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            color: $white-color;
            background-color: $primary-color;
            &:hover{
                color: $white-color;
                background-color: $primary-color;
            }
        }
        .sidebar_right{
            width: 50px;
            position: fixed;
            right: 0;
            bottom: 0;
            top: 0;
            background: $overlay-sidebar-bg;
            border-left: 1px solid $default-border;
            height: 100vh;
            .btn_esc_close{
                display: block;
                align-items: center;
                width: 100%;
                border-radius: 0;
                color: $gray-color;
                padding: 15px 0;
                font-size: 13px;
                font-weight: 700;
                border-bottom: 1px solid $default-border;
                &:hover{
                    background: transparent;
                    color: $default-color;
                }
            }
        }
        .d_header .tab_panel_holder{
            background-color: $white-color;
            border-bottom: 1px solid $default-border;
            display: flex;
            justify-content: space-between;
            padding: 15px 30px;
            align-items: center;
            z-index: 1;
            .header_flx_box{
                display: flex;
                gap: 20px;
                align-items: center;
                .avater_box{
                    img{
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                    }
                }
                .title_box{
                    h4{
                        font-size: 18px;
                        font-weight: 700;
                        color: $title-color;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        margin: 0 0 5px;
                        .dropdown_btn{
                            padding: 0;
                            color: $gray-color;
                        }
                        label{
                            color: $inquiry_label_color;
                            font-size: 10px;
                            font-weight: 800;
                            margin: 0 0 0 10px;
                            border-radius: 4px;
                            text-transform: uppercase;
                            padding: 3px 10px;
                            background-color: $inquiry_label_bg;
                        }
                        img{
                            max-height: 12px;
                        }
                    }
                    h6{
                        color: $table-arrow-color;
                        font-size: 14px;
                        margin: 0 0 5px;
                    }
                    p{
                        font-size: 12px;
                        color: $table-arrow-color;
                        margin: 0;
                        span{
                            display: inline-flex;
                            margin: 0 5px 0 10px;
                            gap: 2px;
                            position: relative;
                            top: 1px;
                            color: $default-color;
                            font-weight: 600;
                            .MuiSvgIcon-root{
                                position: relative;
                                top: 1.5px;
                                color: $table-arrow-color;
                            }
                        }
                    }

                }
                .btn_box{
                    padding-left: 30px;
                }
            }
            .btn_holder{
                display: flex;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;
                .btn_mui_icon_outline {
                    height: 34px;
                    font-weight: 700;
                    padding: 5px;
                    min-width: 34px;
                    font-size: 14px;
                    border-color: $default-border;
                    color: $nav-color;
                    &:hover{
                        color: $primary-color;
                    }

                }
            }
        }
        .main_tabs{
            background-color: $white-color;
            box-shadow: 0 6px 13px 0 rgba(0,0,0,0.05);
            z-index: 1;
            .MuiTab-root{
                font-weight: 700;
                color: $chip-btn-color;
                font-size: 14px;
                padding: 15px 30px;
                text-transform: capitalize;
            }
            .Mui-selected{
                color: $overlay-sidebar-tab-selected-color;
            }
            .MuiTabs-indicator{
                height: 3px;
            }
        }
        .d_content{
            padding: 30px 30px 50px;
            height: 100%;
            overflow-y: auto;
        }
        @media(max-width: 991px){
            .d_header .tab_panel_holder{
                flex-wrap: wrap;
                .btn_holder{
                    margin: 10px 0;
                }
            }
        }
        @media(max-width: 767px){
            .d_header .tab_panel_holder{
                .header_flx_box{
                    flex-wrap: wrap;
                    gap: 10px;
                    .btn_box{
                        padding-left: 0;
                    }
                }
            }
        }
        @media(max-width: 575px){
            width: 100%;
            ::-webkit-scrollbar {
                display: none;
            }  
            .btn_circle_close{
                // top: 20px;
                // left: calc(20% - 51px);
                display: none;
            }
            .d_header .tab_panel_holder{
                padding: 10px 15px;
                height: auto;
                flex-wrap: wrap;
                .title{
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .main_tabs{
                position: sticky;
                .MuiTab-root{
                    font-size: 12px;
                    padding: 10px 15px;
                }
            }
            .d_content{
                padding: 30px 15px 50px;
                height: auto;
                overflow-y: visible;
            }
        }
    }
    @media(max-width: 575px){
        ::-webkit-scrollbar {
            display: none;
        }  
    }
}