body,html {
    .MuiButton-root{
        min-width: auto;
    }
    .btn_mui_primary{
        height: 34px;
        color: $white-color;
        font-weight: 700;
        text-transform: none;
        padding: 5px 15px;
        background: $primary-color !important;
        &:hover{
            background: $primary-color !important;
        }
    }
    .btn_mui_light_blue{
        height: 34px;
        color: $primary-color;
        font-weight: 700;
        text-transform: none;
        padding: 5px 15px;
        background: $btn-light-blue-bg !important;
        border-color: $btn-light-blue-bg !important;
        &:hover{
            background: $btn-light-blue-bg !important;
            border-color: $btn-light-blue-bg !important;
        }
    }
    .has_split_icon{
        .split_icon{
            position: relative;
            left: 5px;
        }
        .MuiButton-endIcon{
            &::after{
                content: "";
                position: absolute;
                height: 34px;
                top: 0;
                width: 1px;
                background-color: rgba(9,132,123,0.59);
            }
        }
    }
    .btn_mui_dflt_outline{
        height: 34px;
        background: $white-color;
        font-weight: 700;
        color: $default-color;
        border-color: $default-border;
        padding: 5px 15px;
        box-shadow: 0 2px 4px 0 $default-shadow;
        font-size: 14px;
        text-transform: none;
        &:hover{
            border-color: $default-border;
            color: $primary-color;
            background: $white-color;
        }
    }
    .btn_mui_dflt_outline.has_split_icon{
        .split_icon{
            position: relative;
            left: 5px;
        }
        .MuiButton-endIcon{
            &::after{
                content: "";
                position: absolute;
                height: 32px;
                top: 0;
                width: 1px;
                background-color: $default-border;
            }
        }
    }
    .btn_mui_dflt_outline.text-uppercase, .btn_mui_primary.text-uppercase{
        font-size: 12px;
    }
    .icon_btn_mui_dflt_outline{
        height: 34px;
        background: $white-color;
        font-weight: 700;
        border-color: $default-border;
        color: $gray-color2;
        padding: 5px 10px;
        font-size: 13px;
        box-shadow: 0 2px 4px 0 $default-shadow;
        &:hover{
            border-color: $default-border;
            color: $primary-color;
            background: $white-color;
        }
    }
    .MuiButton-startIcon>*:nth-of-type(1) {
        font-size: inherit;
    }    
    .has_shadow{
        box-shadow: 0 2px 4px 0 $default-shadow;
    }
    .no_shadow{
        box-shadow: none;
    }
    .shadow_none{
        box-shadow: none;
    }
    .clr_primary{
        color: $primary-color !important;
    }
    .MuiTooltip-tooltip{
        font-size: 11px;
        font-weight: 600;
    }
    .yes_no_switch_holder{
        margin: 0;
        .yes_no_switch{
            padding: 0;
            width: 50px;
            height: 24px;
            opacity: 1;
            .MuiSwitch-track{
                border-radius: 5px;
                background: $nav-color;
                opacity: .8;
                &:before, &:after {
                    content: "";
                    position: 'absolute';
                    top: '50%';
                    transform: 'translateY(-50%)';
                    width: 25px;
                    height: 12px;
                }
                &:before { 
                    content: '';
                    left: 5px;
                    top: 5px;
                    color: $white-color;
                    font-weight: 700;
                    font-size: 11px;
                    position: absolute;
                }
                &:after {
                    content: 'NO';
                    right: -2px;
                    top: 5px;
                    color: $white-color;
                    font-weight: 700;
                    font-size: 11px;
                    position: absolute;
                }
            }
            .Mui-checked+.MuiSwitch-track{
                background: $primary-color;
                opacity: 1;
                &:before { 
                    content: 'YES';
                }
                &:after {
                    content: '';
                }
            }
            .MuiSwitch-thumb {
                box-shadow: none;
                border-radius: 3px;
                background: $white-color;
                width: 18px;
                height: 18px;
                padding: 0;
                margin: 3px 3.5px;
            }
            .MuiSwitch-switchBase{
                padding: 0;
            }
            .Mui-checked{
                transform: translateX(25px);
            }
        }
        .MuiFormControlLabel-label{
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 0 10px;
        }
    }
    .mui_dflt_switch{
        padding: 0;
        width: 50px;
        height: 24px;
        opacity: 1;
        .MuiSwitch-track{
            border-radius: 12px;
            background: $nav-color;
            opacity: .8;
        }
        .Mui-checked+.MuiSwitch-track{
            background: $primary-color;
            opacity: 1;
        }
        .MuiSwitch-thumb {
            box-shadow: none;
            background: $white-color;
            width: 18px;
            height: 18px;
            padding: 0;
            margin: 3px 3.5px;
        }
        .MuiSwitch-switchBase{
            padding: 0;
        }
        .Mui-checked{
            transform: translateX(25px);
        }
    }
    .dflt_dropdown_mui{
        .MuiList-root{
            padding-top: 0;
            padding-bottom: 0;
        }
        .MuiButtonBase-root {
            font-size: 12px;
            color: $light-blue;
            font-weight: 600;
            &:hover{
                color: $default-color;
            }
        }
        .MuiDivider-root {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .dropdown_filter_settings{
        .MuiList-root{
            padding: 0;
        }
        .MuiPaper-elevation{
            width: 305px;
        }
        .dropdown_header{
            border-bottom: 1px solid $default-border3;
            .MuiButtonBase-root{
                width: 50%;
                text-align: center;
                text-transform: capitalize;
                font-weight: 700;
                color: $chip-btn-color;
                font-size: 16px;
                .MuiTabs-indicator{
                    height: 3px;
                }
            }
            .Mui-selected{
                color: $title-color;
            }
        }
        .dropdown_body{
            height: 280px;
            overflow-y: auto;
            background-color: $table-thead-bg;
            .grid_holder{
                padding: 10px 0;
                color: $calendar-title;
                font-size: 14px;
                font-weight: 600;
                .flx_box{
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 15px;
                    align-items: center;
                    gap: 10px;
                    .flx_txt{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        .MuiSvgIcon-root{
                            color: #D3DCE2;
                        }
                    }
                }
            }
            .filter_holder{
                .MuiPaper-root{
                    width: 100%;
                    background-color: transparent;
                    box-shadow: none;
                    border-radius: 0;
                    padding: 0;
                    margin: 0;
                    .MuiAccordionSummary-root{
                        flex-direction: row-reverse;
                        padding: 10px 15px;
                        min-height: auto;
                        border-bottom: 1px solid $default-border3;
                        .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded{
                            margin: 0;
                            padding-left: 10px;
                        }
                    }
                    .MuiAccordionSummary-root.Mui-expanded {
                        min-height: auto;
                    }
                    .MuiAccordionSummary-expandIconWrapper{
                        color: $calendar-weekday-color;
                    }
                    .MuiAccordionDetails-root{
                        border-bottom: 1px solid $default-border3;
                        padding: 5px 0;
                    }
                }
                .MuiPaper-root::before{
                    opacity: 0;
                }
                .box_title{
                    color: $calendar-title;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    span{
                        font-weight: 700;
                    }
                }
                .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
                .Mui-expanded{
                    .box_title{
                        font-weight: 700;
                        span{
                            font-weight: 800;
                        }
                    }
                }
                .flx_box{
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 15px 5px 45px;
                    align-items: center;
                    gap: 10px;
                }
                .rental_holder{
                    padding: 0 15px 15px;
                    .search-holder{
                        margin: 10px 0;
                    }
                    .flx_box{
                        padding: 5px 0;
                        .rental_box{
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            .img_holder{
                                img{
                                    width: 24px;
                                    height: 24px;
                                    border-radius: 50%;
                                }
                            }
                            .rental_dsc{
                                h4{
                                    font-size: 14px;
                                    font-weight: 600;
                                    margin: 0;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    max-width: 165px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .dropdown_ftr{
            display: flex;
            gap: 10px;
            justify-content: center;
            padding: 15px;
            border-top: 1px solid $default-border3;
        }
    }
    .dropdown_clumn_view{
        .MuiPaper-elevation{
            min-width: 190px;
        }
        .MuiButtonBase-root {
            .MuiSvgIcon-root{
                margin-right: 5px;
            }
            &:hover{
                .MuiSvgIcon-root{
                    color: $primary-color;
                }
            }
        }
        .MuiButtonBase-root.selected{
            color: $default-color;
            .MuiSvgIcon-root{
                color: $primary-color;
            }
        }
    }
    .mui_dflt_outlined_input{
        background-color: $white-color;
        label.Mui-focused {
            color: $primary-color;
        }
        .MuiInput-underline:after{
            border-bottom-color: $primary-color;
        }
        .MuiInput-underline:after{
            border-bottom-color: $primary-color;
        }
        .MuiOutlinedInput-root {
            fieldset {
              border-color: $default-border;
            }
            &:hover fieldset {
              border-color: $primary-color;
            }
            .Mui-focused fieldset{
              border-color: $primary-color;
            }
        }
    }
    .mui_dflt_outlined_input.has_icon_divider{
        .MuiInputBase-input{
            text-align: center;
            border: 1px solid $default-border;
            border-width: 0 1px;
            &:hover{
                border-color: $primary-color;
            }
            &:focus{
                border-width: 0 2px;
                border-color: $primary-color;
            }
        }
        .MuiOutlinedInput-root{
            padding-left: 0;
            padding-right: 0;
        }
        .MuiInputAdornment-root{
            margin: 0;
            padding: 2px;
        }
        &:hover{
            .MuiInputBase-input{
                border-color: $primary-color;
            }
        }
    }
    .mui_dflt_outlined_input.has_icon_divider.has_input_icon{
        .MuiInputBase-input{
            text-align: left;
            padding-left: 20px;
        }
        .MuiInputAdornment-root{
            position: relative;
            .input_icon{
                position: absolute;
                right: -22px;
                font-size: 17px;
                color: $gray-color;
            }
        }
    }
    .mui-dflt-select-box.has_img{
        .MuiInputBase-input{
            img{
                max-width: 30px;
                max-height: 18px;
                margin-right: 5px;
            }
        }
    }
    .mui_dflt_outlined_input.read-only{
        background: $input-bg-readonly;
        pointer-events: none;
        .MuiSvgIcon-root{
            color: $nav-color;
        }
        .MuiInputBase-root{
            color: $gray-color2;
        }
        .clr_primary{
            color: $primary-color !important;
        }
    }
    .search_box_under_select{
        padding: 5px 15px 10px;
        .mui_dflt_outlined_input{
            width: 100%;
            .MuiInputBase-input{
                height: 15px;
                font-size: 14px;
                color: $chip-btn-color;
            }
        }
    }
    .d_flx_img_menu_item{
        display: flex;
        gap: 10px;
        align-items: center;
        color: $chip-btn-color;
        img{
            max-width: 18px;
            max-height: 18px;
        }
    }
    .has_l_icon{
        .MuiOutlinedInput-root{
            padding-left: 5px;
        }
        .MuiInputAdornment-root{
            margin-right: 3px;
        }
    }
    .has_r_icon{
        .MuiOutlinedInput-root{
            padding-right: 5px;
        }
        .MuiInputAdornment-root{
            margin-left: 3px;
        }
    }
    .search_header{
        width: 100%;
        max-width: 500px;
        .MuiOutlinedInput-root{
            background-color: $table-thead-bg;
            @media(min-width: 576px){
                height: 40px;
            }
        }
    }
    .mui-dflt-select-box{
        background-color: $white-color;
        .MuiSelect-select{
            padding: 8.5px 32px 8.5px 14px;
            font-weight: 600;
            font-size: 14px;
        }
        label.Mui-focused {
            color: $primary-color;
        }
        .MuiInput-underline:after{
            border-bottom-color: $primary-color;
        }
        .MuiInput-underline:after{
            border-bottom-color: $primary-color;
        }
        .MuiOutlinedInput-root {
            fieldset {
            border-color: $default-border;
            }
            &:hover fieldset {
            border-color: $primary-color;
            }
            .Mui-focused fieldset{
            border-color: $primary-color;
            }
        }
    }
    .mui-dflt-select-menu-box.Mui-selected{
        .MuiTypography-root{
            font-weight: 700;
        }
    }
    .MuiCheckbox-root{
        color: rgba(0,0,0,0.3);
        position: relative;
        left: -9px;
        margin-right: -9px;
    }
    .Mui-checked{
        color: $primary-color;
    }

    .mui_select_country{
        background-color: $white-color;
        .MuiSelect-select{
            padding: 8.5px 32px 8.5px 14px;
            font-weight: 600;
            font-size: 14px;
        }
        label.Mui-focused {
            color: $primary-color;
        }
        .MuiInput-underline:after{
            border-bottom-color: $primary-color;
        }
        .MuiInput-underline:after{
            border-bottom-color: $primary-color;
        }
        .MuiOutlinedInput-root {
            fieldset {
            border-color: $default-border;
            }
            &:hover fieldset {
            border-color: $primary-color;
            }
            .Mui-focused fieldset{
            border-color: $primary-color;
            }
            .MuiAutocomplete-input{
                padding: 0 4px 0 6px;
            }
        }
    }
}