@import '../../../../../../assets/scss/variables';
.activity_logs_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .container-box{
        border: 1px solid $rates_unavailable;
        border-radius: 5px;
        background-color: $white-color;
        margin: 0 0 15px;
        .table-holder{
            .table{
                table-layout: fixed;
                margin: 0;
                border-color: $rates_unavailable;
                thead{
                    tr{
                        background-color: $table-thead-bg2;
                        th{
                            border: 1px solid $default-border;
                            height: 40px;
                            border-width: 0 0 1px;
                            vertical-align: middle;
                            text-align: left;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $gray-color2;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 100px;
                            .sort_icon{
                                font-size: 18px;
                                margin-left: 5px;
                                color: $nav-color;
                                cursor: pointer;
                                &:hover{
                                    color: $default-color;
                                }
                            }
                            .info_icon{
                                color: $nav-color;
                                font-size: 16px;
                                position: relative;
                                top: 2px;
                                margin-left: 5px;
                                &:hover{
                                    color: $primary-color;
                                }
                            }
                        }
                        th:first-child {
                            padding-left: 25px;
                            width: 220px;
                            border-top-left-radius: 5px;
                        }
                        th:last-child{
                            padding-right: 25px;
                            width: 350px;
                            border-top-right-radius: 5px;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            vertical-align: middle;
                            font-size: 14px;
                            font-weight: 600;
                            white-space: nowrap;
                            width: 100px;
                        }
                        td:first-child {
                            padding-left: 25px;
                            width: 220px;
                        }
                        td:last-child{
                            padding-right: 25px;
                            width: 350px;
                        }
                    }
                }
            }
        }
    }
}