@import '../../../../assets/scss/variables';
.rates-calendar-page{
    .content_holder{  
        .title-holder{
            margin-bottom: 5px;
            .title{
                display: flex;
                align-items: center;
                gap: 15px;
                margin: 10px 0;
                h4{
                    margin: 0;
                    font-size: 21px;
                    font-weight: 700;
                    color: $title-color;
                }
                a{
                    color: $gray-color;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        color: $primary-color;
                    }
                }
                @media(max-width: 767px){
                    gap: 10px;
                    h4{
                        font-size: 16px;
                    }
                    a{
                        font-size: 16px;
                    }
                }
            }
            .btn-group-box{
                border: 1px solid $default-border;
                display: flex;
                align-items: center;
                border-radius: 4px;
                padding: 3px;
                margin: 10px 0;
                a{
                    margin: 0 0 0 10px;
                    &:first-child{
                        margin: 0;
                    }
                    button{
                        min-width: auto;
                        padding: 5px;
                        font-size: 18px;
                        border-radius: 3px;
                        color: $gray-color;
                    }
                    .active{
                        background-color: $white-color;
                        color: $primary-color;
                        border: 1px solid $default-border;
                    }
                    &:hover{
                        button{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .header-input-box{
            margin-bottom: 15px;
            .flx-box{
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 0 0 10px;
                .switch_holder{
                    label{
                        font-weight: 700;
                        margin-right: 10px;
                        font-size: 14px;
                    }
                }
                .status_toggle_btn_grp{
                    background: $white-color;
                    border-color: $default-border;
                    border-radius: 5px;
                    height: 34px;
                    font-size: 12px;
                    text-transform: uppercase;
                    button{
                        font-weight: 700;
                        i{
                            margin-right: 5px;
                            display: none;
                        }
                    }
                    .Mui-selected{
                        background: $primary-color;
                        color: $white-color;
                        i{
                            display: inline-flex;
                        }
                    }
                }
                @media(max-width: 575px){
                    flex-wrap: wrap;
                    gap: 5px;
                }
            }
        }
        .table-holder{
            .table{
                table-layout: fixed;
                margin: 0;
                thead{
                    tr{
                        th{
                            border: 1px solid $default-border;
                            position: sticky;
                            top: 0;
                            z-index: 2;
                            width: 70px;
                            height: 60px;
                            border-width: 0 1px 1px 0;
                            background: $white-color;
                            vertical-align: middle;
                            text-align: center;
                            font-size: 14px;
                            .day{
                                color: $gray-color2;
                                font-weight: 700;
                                display: block;
                            }
                            .date{
                                font-weight: 700;
                                display: block;
                            }
                            .table_pagination{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                color: $tbl-top-pagination-color;
                                .MuiIconButton-root{
                                    width: 30px;
                                    height: 30px;
                                }
                                span{
                                    color: $title-color;
                                }
                            }
                        }
                        th:first-child {
                            position: sticky;
                            left: 0;
                            z-index: 3;
                            width: 230px;
                            border-right: 0;
                            background: $white-color;
                            padding: 8px 15px;
                            &::after{
                                content: "";
                                position: absolute;
                                width: 1px;
                                background: $default-border;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }
                            
                        }
                        th:last-child{
                            border-right: 0;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            width: 70px;
                            height: 60px;  
                            border: 1px solid $default-border;
                            position: relative;
                            border-width: 0 1px 1px 0;
                            vertical-align: middle;
                            padding: 0;
                            .td_content{
                                display: block;
                                text-align: center;
                                color: $default-color;
                                font-size: 14px;
                                font-weight: 600;
                                height: 100%;
                                padding: 10px 8px;
                                strong{
                                    font-weight: 700;
                                }
                            }
                            .rates_modified{
                                border: 1px solid $rates_modified_border;
                                background-color: $rates_modified_bg;
                                color: $rates_td_color;
                                cursor: pointer;
                                strong{
                                    color: $rates_td_modified_color;
                                }
                            }
                            .rates_highdemand{
                                border: 1px solid $rates_highdemand_border;
                                background-color: $rates_highdemand_bg;
                                color: $rates_td_color;
                                cursor: pointer;
                                strong{
                                    color: $rates_highdemand_color;
                                }
                            }
                            .rates_lowdemand{
                                border: 1px solid $rates_lowdemand_border;
                                background-color: $rates_lowdemand_bg;
                                color: $rates_td_color;
                                cursor: pointer;
                                strong{
                                    color: $rates_highdemand_color;
                                }
                            }
                            .rates_exceeded{
                                border: 1px solid $rates_exceeded_border;
                                background-color: $rates_exceeded_bg;
                                color: $rates_td_color;
                                cursor: pointer;
                                strong{
                                    color: $rates_exceeded_color;
                                }
                            }
                            .flx_icon_abs_box{
                                position: absolute;
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                right: 10px;
                                bottom: 10px;
                            }
                        }
                        td.booked_dates{
                            background: url('../../../../assets/images/calendar-lines-green.png') no-repeat;
                            background-position: top;
                            background-size: cover;
                        }
                        td.p_booked_dates{
                            background: url('../../../../assets/images/calendar-lines.png') no-repeat;
                            background-position: top;
                            background-size: cover;
                        }
                        td.unavailable{
                            background: url('../../../../assets/images/calendar-lines-grey.png') no-repeat;
                            background-position: top;
                            background-size: cover;
                        }
                        td.rental_box_holder{
                            .rental_box{
                                display: flex;
                                gap: 12px;
                                align-items: center;
                                .img_holder{
                                    img{
                                        width: 32px;
                                        height: 32px;
                                        border-radius: 5px;
                                    }
                                }
                                .rental_dsc{
                                    h4{
                                        font-size: 14px;
                                        font-weight: 600;
                                        margin: 0 0 2px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        max-width: 160px;
                                    }
                                    .flx_icon_box{
                                        display: flex;
                                        gap: 15px;
                                        align-items: center;
                                        div{
                                            display: flex;
                                            align-items: center;
                                            gap: 3px;
                                            span{
                                                font-weight: 600;
                                                font-size: 12px;
                                            }
                                            .MuiSvgIcon-root,i{
                                                color: $nav-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        td:first-child {
                            position: sticky;
                            left: 0;
                            z-index: 2;
                            width: 230px;
                            border-right: 0;
                            background: $white-color;
                            padding: 8px 15px;
                            &::after{
                                content: "";
                                position: absolute;
                                width: 1px;
                                background: $default-border;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }
                        }
                        td:last-child{
                            border-right: 0;
                        }
                    }
                    tr:last-child{
                        td{
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
        @media(max-width: 575px){
            .scroll_btn_left{
                left: 0;
            }
            .table-holder{
                .table{
                    thead{
                        tr{
                            th{
                                position: relative;
                            }
                            th:first-child {
                                position: relative;
                                padding: 8px 15px 8px 20px;
                            }
                        }
                    }
                    tbody{
                        tr{
                            td:first-child {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }
    .status_info_flx_box{
        display: flex;
        gap: 20px;
        font-weight: 700;
        color: $rates_status_color;
        font-size: 14px;
        margin: 15px 0 100px;
        flex-wrap: wrap;
        .status_box{
            display: flex;
            align-items: center;
            gap: 8px;
            .clr_box{
                width: 20px;
                height: 20px;
            }
            .booked_dates{
                border: 1px solid $rates_booked_dates;
                background: url('../../../../assets/images/booked_dates_bg_xs.png') no-repeat;
                background-size: 100% 100%;
            }
            .unavailable{
                border: 1px solid $rates_unavailable;
                background: url('../../../../assets/images/unavailable_bg_xs.png') no-repeat;
                background-size: 100% 100%;
            }
            .rates_modified{
                border: 1px solid $rates_modified_border;
                background-color: $rates_modified_bg;
                color: $rates_td_color;
                cursor: pointer;
                strong{
                    color: $rates_td_modified_color;
                }
            }
            .high_demand{
                border: 1px solid $rates_highdemand_border;
                background-color: $rates_highdemand_bg;
            }
            .low_demand{
                border: 1px solid $rates_lowdemand_border;
                background-color: $rates_lowdemand_bg;
            }
            .rates_exceeded{
                border: 1px solid $rates_exceeded_border;
                background-color: $rates_exceeded_bg;
            }
        }
    }
}
.popper_holder{
    .header_box{
        padding: 7.5px 15px;
        border-radius: 5px 5px 0 0;
        background-color: $anchor-color;
        font-size: 12px;
        color: $white-color;
        font-weight: 700;
    }
    .content_box{
        padding: 0 15px 20px;
        font-size: 12px;
        color: $default-color;
        .row{
            div{
                margin-top: 20px;
                .rate{
                    font-size: 16px;
                }
            }   
        }
    }
}