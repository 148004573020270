@import '../../../../../../assets/scss/variables';
.rental_tax_vat_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .container-box{
        border: 1px solid $default-border;
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 -2px 4px 0 $default-shadow;
        position: relative;
        padding: 15px 30px;
        margin: 0 0 15px;
        @media(max-width: 767px){
            padding: 15px;
        }
        .input_box{
            margin: 10px 0;
            label{
                display: block;
                font-size: 14px;
                font-weight: 700;
                margin: 0 0 5px;
            }
        }
    }
}