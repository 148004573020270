@import '../../../../../../assets/scss/variables';
.basic_details_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .inputbox_holder{
        .container-box{
            border: 1px solid $default-border;
            border-radius: 5px;
            background-color: $white-color;
            box-shadow: 0 -2px 4px 0 $default-shadow;
            padding: 20px 20px 0;
            margin: 0 0 25px;
            .box_title{
                color: $gray-color2;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                margin: 0 0 20px;
            }
            .input_box{
                margin-bottom: 20px;
                font-weight: 600;
                position: relative;
                label{
                    font-size: 14px;
                    font-weight: 700;
                    color: $title-color;
                    margin: 0 0 5px;
                    display: block;
                    span{
                        font-weight: normal;
                        color: $gray-color2;
                    }
                    .info_icon{
                        margin-left: 5px;
                        cursor: pointer;
                        color: $nav-color;
                        font-size: 16px;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
                .MuiCheckbox-root{
                    padding: 7px;
                }
            }
        }
    }
}