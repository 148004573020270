// ===Fonts===
$default-font: 'Nunito Sans', sans-serif;

// =========All Colors=========
$default-bg: #f3f5f7;
$default-color: #475059;
$default-scroll-bar-color: rgba(2, 171, 157, .5);
$default-scroll-bar-hvr-color: rgba(2, 171, 157, .8);
$default-shadow: rgba(226,230,234,0.76);
$default-border: #E2E6EA;
$default-border2: #ECF2F7;
$default-border3: #EBEEF0;
$anchor-color: #03A99D;

$nav-color: #BDC4CB;
$nav-profile-bg: #00AC9D;
$primary-color: #02AB9D;
$title-color: #222426;
$btn-light-blue-bg: #DFF4F2;

$black-color: #000000;
$white-color: #ffffff;
$light-bg: #FCFCFC;
$light-red: #D65E51;
$gray-color: #9AA7B7;
$gray-color2: #899098;
$light-blue: #688E99;
$green-color: #55AE2A;
$blue-color: #4693F7;
$red-color: #C13139;
$red-color-2: #D06363;
$yellow-color: #FACE47;

$table-arrow-color: #668D98;
$table-thead-bg: #F8F9FA;
$table-thead-bg2: #fbfcfc;
$event-bg: #FAFBFB;
$event-skew-border: rgba(178,195,214,0.57);
$event-skew-border-2: rgba(205,217,231,0.93);
$chip-btn-color: #668D9D;
$chip-btn-border: #B9C6D2;
$calendar-title: #002F53;
$calendar-disable-text: #BAC2C7;
$calendar-weekday-color: #668D98;
$calendar-day-color: #2C3135;
$calendar-event-bg-green: rgba(85, 174, 42, .5);
$calendar-event-bg-blue: rgba(41, 162, 250, .5);
$paid-bg: rgba(85,174,42,0.15);
$unpaid-bg: rgba(214,94,81,0.19);
$tbl-top-pagination-color: #889099;
$rates_modified_border: rgba(70,147,247,0.28);
$rates_modified_bg: #E0EDFE;
$rates_td_color: #46505A;
$rates_td_modified_color: #0C4D7E;
$rates_highdemand_border: #90BE7B;
$rates_highdemand_bg: rgba(171,227,144,0.61);
$rates_highdemand_color: #24510F;
$rates_lowdemand_border: rgba(168,221,142,0.83);
$rates_lowdemand_bg: rgba(171,227,144,0.22);
$rates_exceeded_border: rgba(175,23,48,0.2);
$rates_exceeded_bg: #FBE4E2;
$rates_exceeded_color: #AF1730;
$rates_status_color: #415364;
$rates_booked_dates: #CDF399;
$rates_unavailable: #D1E5E3;
$info-box-border:  rgba(2,171,157,0.39);
$info-box-bg: #F6FCFB;

$overlay-content-bg: #F9FAFB;
$invoice-unpaid-bg: #FFF2F2;
$invoice-paid-bg: #F6FCFB;
$input-bg-readonly: rgba(189,196,203,0.15);
$chip-bg-2: #F3F6F7;

$auth-gradient-bg: linear-gradient(130.3deg, #03A99D 0%, #5CBC63 100%);
$auth-slider-text-color: #ABE8E3;
$overlay-sidebar-bg: #F5F6F8;
$overlay-sidebar-tab-selected-color: #232327;
$rates-title-icon-color: #A8C3C0;
$rates-table-border: #D4EFFA;
$rates-td-default-bg: #EAF7FD;
$rates-clr_blue: #0A8CEB;
$rates-clr_light_blue_border: #BFE7F8;
$rates-clr_dark_blue: #A9DFF5;
$rates-clr_dark_blue_border: #94D8F3;
$rating-color: #fca334;
$status_all_blue: #0075CF;
$status_pastdue: #E7911B;
$inquiry_label_color: #3E9713;
$inquiry_label_bg: rgba(85,174,42,0.15);
$inquiry_avatar-border: #D8DEE1;
$inquiry_content_selected_bg: #FAFFF8;