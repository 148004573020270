@import '../../../../assets/scss/variables';
.yearly-calendar-page{
    .content_holder{  
        .title-holder{
            margin-bottom: 5px;
            .title{
                display: flex;
                align-items: center;
                gap: 15px;
                margin: 10px 0;
                h4{
                    margin: 0;
                    font-size: 21px;
                    font-weight: 700;
                    color: $title-color;
                }
                a{
                    color: $gray-color;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        color: $primary-color;
                    }
                }
                @media(max-width: 767px){
                    gap: 10px;
                    h4{
                        font-size: 16px;
                    }
                    a{
                        font-size: 16px;
                    }
                }
            }
            .btn-group-box{
                border: 1px solid $default-border;
                display: flex;
                align-items: center;
                border-radius: 4px;
                padding: 3px;
                margin: 10px 0;
                a{
                    margin: 0 0 0 10px;
                    &:first-child{
                        margin: 0;
                    }
                    button{
                        min-width: auto;
                        padding: 5px;
                        font-size: 18px;
                        border-radius: 3px;
                        color: $gray-color;
                    }
                    .active{
                        background-color: $white-color;
                        color: $primary-color;
                        border: 1px solid $default-border;
                    }
                    &:hover{
                        button{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .header-input-box{
            margin-bottom: 15px;
            .flx-box{
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 0 0 10px;
                .range_date_picker_holder{
                    display: flex;
                    align-items: center;
                    height: 34px;
                    border: 1px solid $default-border;
                    background-color: $white-color;
                    border-radius: 5px;
                    font-size: 14px;
                    padding: 0 0 0 5px;
                    font-weight: 600;
                    &:focus-within{
                        border-color: $primary-color;
                    }
                    .input_date{
                        width: 110px;
                        padding: 0 5px;
                        font-weight: 600;
                    }
                    span{
                        color: $gray-color2;
                    }
                    button{
                        width: 30px;
                        height: 30px;
                        color: $nav-color;
                        i{
                            font-size: 16px;
                        }
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
                .status_toggle_btn_grp{
                    background: $white-color;
                    border-color: $default-border;
                    border-radius: 5px;
                    height: 34px;
                    font-size: 12px;
                    text-transform: uppercase;
                    button{
                        font-weight: 700;
                        i{
                            margin-right: 5px;
                            display: none;
                        }
                    }
                    .Mui-selected{
                        background: $primary-color;
                        color: $white-color;
                        i{
                            display: inline-flex;
                        }
                    }
                }
                @media(max-width: 575px){
                    flex-wrap: wrap;
                    gap: 5px;
                }
            }
        }
        .container-box{
            padding: 30px 30px 10px;
            background-color: $light-bg !important;
            .calendar-box-holder{
                margin-bottom: 20px;
                .calendar-box{
                    height: 100%;
                    background-color: $white-color;
                    border: 1px solid $default-border;
                    .box-title{
                        padding: 10px 20px;
                        text-align: center;
                        color: $calendar-title;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 14px;
                        border-bottom: 1px solid $default-border;
                    }
                    .box-content{
                        padding: 15px 15px 10px;
                        .weekdays{
                            margin: 0;
                            padding: 10px 0;
                            font-weight: 700;
                            font-size: 12px;
                            text-transform: uppercase;
                            li {
                                display: inline-block;
                                width: 14.28%;
                                color: $calendar-weekday-color;
                                text-align: center;
                            }
                        }
                        .days{
                            padding: 10px 0;
                            margin: 0;
                            li {
                                list-style-type: none;
                                display: inline-block;
                                width: 14.28%;
                                text-align: center;
                                margin-bottom: 5px;
                                padding: 5px 0;
                                font-size:12px;
                                color: $calendar-day-color;
                                overflow: hidden;
                            }
                            li.disable {
                                color: $calendar-disable-text;
                                pointer-events: none;
                            }
                            li.has_event{
                                position: relative;
                                cursor: pointer;
                                span{
                                    font-weight: 700;
                                    color: $calendar-title;
                                    position: relative;
                                    z-index: 1;
                                }
                            }
                            li.has_event.bg_green{
                                background: $calendar-event-bg-green
                            }
                            li.has_event.bg_blue{
                                background: $calendar-event-bg-blue;
                            }
                            li.has_event.event_start::after{
                                content: "";
                                position: absolute;
                                transform: skewX(-30deg);
                                width: 55%;
                                left: -30%;
                                top: 0;
                                bottom: 0;
                                background: $white-color;
                            }
                            li.has_event.event_end::after{
                                content: "";
                                position: absolute;
                                transform: skewX(-30deg);
                                width: 55%;
                                right: -30%;
                                top: 0;
                                bottom: 0;
                                background: $white-color;
                            }
                        }
                    }
                }
            }
            @media(max-width: 575px){
                padding: 15px 15px 0;
                .calendar-box-holder{
                    margin-bottom: 15px;
                }
            }
        }
    }
}