@import '../../../../assets/scss/variables';
.add-payments-page{
    .content_holder{  
        .back_to_pev_page{
            margin: 15px 0 30px;
            a{
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 800;
                .MuiSvgIcon-root, i{
                    margin-right: 8px;
                }
            }
        }
        .info_box{
            display: flex;
            gap: 15px;
            border: 1px solid$info-box-border;
            border-radius: 5px;
            background-color: $info-box-bg;
            margin: 20px 0 30px;
            padding: 20px;
            p{
                font-size: 12px;
                font-weight: 600;
                margin: 0;
            }
        }
        .payment_gateways{
            .box_holder{
                margin: 0 0 20px;
                .box{
                    border: 1px solid $default-border;
                    border-radius: 5px;
                    background-color: $white-color;
                    box-shadow: 0 2px 4px 0 $default-shadow;
                    padding: 30px;
                    height: 100%;
                    .logo_holder{
                        display: flex;
                        align-items: center;
                        height: 70px;
                        justify-content: center;
                        img{
                            max-width: 75%;
                            max-height: 42px;
                        }
                    }
                    .box_content{
                        color: $gray-color2;
                        font-size: 12px;
                        margin: 15px 0 30px;
                        p{
                            .more{
                                font-weight: 700;
                            }
                        }
                        ul{
                            padding: 0 0 0 15px;
                            margin: 10px 0;
                            li{
                                margin: 0 0 5px;
                            }
                        }
                    }
                    .btn_holder{
                        text-align: center;
                        .btn_mui_primary{
                            min-width: 124px;
                        }
                    }
                }
            }
            .select_country{
                margin: 50px 0 20px;
                h4{
                    font-size: 14px;
                    font-weight: 700;
                    margin: 0 0 5px;
                }
            }
        }
        
        @media(max-width: 767px){
            .payment_gateways{
                .box_holder{
                    .box{
                        padding: 30px 20px;
                    }
                }
                .select_country{
                    margin: 30px 0 20px;
                }
            }
        }
    }
}