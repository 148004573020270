@import '../../../../assets/scss/variables';
.invoices_overlay_holder{
    .MuiPaper-root{
        width: 85%;
        .btn_circle_close{
            position: fixed;
            top: 25px;
            left: calc(15% - 61px);
            z-index: 1;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            color: $white-color;
            background-color: $primary-color;
            &:hover{
                color: $white-color;
                background-color: $primary-color;
            }
        }
        .d_header{
            background-color: $white-color;
            height: 70px;
            border-bottom: 1px solid $default-border;
            display: flex;
            justify-content: space-between;
            padding: 10px 30px;
            align-items: center;
            z-index: 1;
            .title{
                font-weight: 700;
                color: $title-color;
                font-size: 21px;
            }
            .btn_holder{
                display: flex;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;
                .btn_mui_icon_outline {
                    height: 34px;
                    font-weight: 700;
                    padding: 5px;
                    min-width: 34px;
                    font-size: 14px;
                    border-color: $default-border;
                    color: $nav-color;
                    &:hover{
                        color: $primary-color;
                    }

                }
            }
        }
        .d_content{
            padding: 15px 30px 100px;
            background-color: $overlay-content-bg;
            position: absolute;
            left: 0;
            right: 0;
            top: 70px;
            bottom: 0;
            overflow-y: auto;
            .back_to_pev_page{
                margin: 15px 0 30px;
                a{
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 800;
                    .MuiSvgIcon-root, i{
                        margin-right: 8px;
                    }
                }
            }
            .payment_status_box{
                margin: 0 0 30px;
                border: 2px solid $default-border;
                border-radius: 5px 5px 0 0;
                background-color: $white-color;
                box-shadow: 0 2px 4px 0 $default-shadow;
                padding: 15px 30px;
                .company_logo{
                    img{
                        max-height: 90px;
                        max-width: 100%;
                    }
                }
                .unpaid{
                    height: 34px;
                    width: 167px;
                    border: 1px solid $light-red;
                    border-radius: 5px;
                    background-color: $invoice-unpaid-bg;
                    color: $light-red;
                    font-size: 14px;
                    font-weight: 700;
                    padding: 0 15px;
                    display: flex;
                    margin: 15px 0;
                    align-items: center;
                }
                .paid{
                    height: 34px;
                    width: 167px;
                    border: 1px solid $primary-color;
                    border-radius: 5px;
                    background-color: $invoice-paid-bg;
                    color: $primary-color;
                    font-size: 14px;
                    font-weight: 700;
                    padding: 0 15px;
                    display: flex;
                    margin: 15px 0;
                    align-items: center;
                }
                @media(max-width: 575px){
                    padding: 15px;
                    text-align: center;
                    .unpaid, .paid{
                        width: 100%;
                    }
                }
            }
            .invoice_info_flx_box{
                h6{
                    color: $gray-color2;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0 0 10px;
                    text-transform: uppercase;
                }
                h1{
                    font-weight: 700;
                    font-size: 24px;
                    color: $title-color;
                    margin: 0 0 30px;
                }
                p{
                    color: $gray-color2;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 0 30px;
                    strong{
                        color: $title-color;
                        font-weight: 800;
                    }
                }
                .input_box{
                    margin: 0 0 30px;
                    label{
                        display: block;
                        font-size: 14px;
                        font-weight: 700;
                        margin: 0 0 5px;
                    }
                }
                @media(max-width: 1199px){
                    h1{
                        font-size: 18px;
                        margin: 0 0 15px;
                    }
                    .input_box{
                        margin: 0 0 10px;
                    }
                }
                @media(max-width: 767px){
                    .box{
                        margin-bottom: 20px;
                        p{
                            margin: 0 0 15px;
                        }
                    }
                }
            }
            .messages{
                margin: 0 0 50px;
                h6{
                    color: $gray-color2;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0 0 10px;
                    text-transform: uppercase;
                }
                p{
                    font-size: 14px;
                    font-weight: 600
                }
            }
            .item_tbl_title_box{
                margin: 0 0 10px;
                color: $gray-color2;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                .item_left{
                    padding: 0 30px;
                }
                .item_right{
                    padding: 0 45px;
                }
                @media(max-width: 1199px){
                    display: none;
                }
            }
            .item_tbl_row{
                margin: 0 0 18px;
                border: 2px solid $default-border;
                border-radius: 5px 5px 0 0;
                background-color: $white-color;
                box-shadow: 0 2px 4px 0 $default-shadow;
                padding: 20px;
                label{
                    display: block;
                    margin: 0 0 5px;
                    color: $gray-color2;
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                .input_box{
                    margin: 0 0 10px;
                }
                @media(min-width: 1200px){
                    padding: 0;
                    label{
                        display: none;
                    }
                    .item_left{
                        padding: 30px;
                    }
                    .item_right{
                        padding: 30px;
                        border-left: 1px solid $default-border;
                    }
                }
                @media(max-width: 767px){
                    padding: 15px 5px;
                }
            }
        }
        @media(max-width: 575px){
            width: 80%;
            .btn_circle_close{
                top: 20px;
                left: calc(20% - 51px);
            }
            .d_header{
                padding: 10px 20px;
                .title{
                    font-size: 18px;
                }
            }
            .d_content{
                padding: 15px 20px 50px;
            }
        }
    }
}