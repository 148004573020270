@import '../../../../../assets/scss/variables';
.expenses_tab_holder{
    .expenses_content_box{
        border: 1px solid $default-border;
        border-radius: 5px 5px 0 0;
        background-color: $white-color;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border-width: 1px 1px 0;
        bottom: -50px;
        display: flex;
        .expenses_sidebar{
            width: 30%;
            border-right: 1px solid $default-border;
            position: relative;
            ::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }  
            .search_box{
                padding: 15px 20px;
                display: flex;
                gap: 10px;
                border-bottom: 1px solid $default-border;
                .MuiSvgIcon-root{
                    color: $calendar-weekday-color;
                }
            }
            .expenses_list_holder{
                position: absolute;
                top: 68px;
                left: 0;
                right: 0;
                bottom: 0;
                overflow-y: auto;
                overflow-x: hidden;
                .expenses_list{
                    width: 100%;
                    border-bottom: 1px solid $default-border;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 0;
                    padding: 10px 20px;
                    text-transform: none;
                    align-items: flex-end;
                    .flx-box{
                        display: flex;
                        text-align: left;
                        gap: 15px;
                        align-items: center;
                        .avatar{
                            width: 32px;
                            height: 32px;
                            min-width: 32px;
                            min-height: 32px;
                            border: 1px solid $green-color;
                            background-color: rgba(85,174,42,0.08);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            img{
                                max-height: 20px;
                                max-width: 25px;
                            }
                        }
                        h4{
                            margin: 0;
                            color: $default-color;
                            font-size: 14px;
                            font-weight: 600;
                        }
                        p{
                            margin: 0;
                            color: $chip-btn-color;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                    .status_box{
                        text-align: right;
                        line-height: 22px;
                        .MuiSvgIcon-root{
                            font-size: 16px;
                            color: $chip-btn-color;
                        }
                        .paid{
                            color: $green-color;
                            font-size: 12px;
                            font-weight: 700;
                        }
                        .unpaid{
                            color: $light-red;
                            font-size: 12px;
                            font-weight: 700;
                        }
                    }
                }
                .expenses_list.active{
                    .flx-box{
                        h4{
                            margin: 0;
                            color: $title-color;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                    &::after{
                        content: "";
                        background: $primary-color;
                        width: 4px;
                        position: absolute;
                        top: -1px;
                        bottom: -1px;
                        right: 0;
                    }
                }
            }
        }
        .expenses_content{
            position: relative;
            width: 70%;
            .title_box{
                padding: 15px 30px;
                border-bottom: 1px solid $default-border;
                h4{
                    margin: 0;
                    color: #222426;
                    font-size: 21px;
                    font-weight: 700;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    .paid{
                        color: $inquiry_label_color;
                        font-size: 10px;
                        font-weight: 800;
                        margin: 0 5px 0 0;
                        border-radius: 4px;
                        text-transform: uppercase;
                        padding: 3px 10px;
                        background-color: $inquiry_label_bg;
                    }
                }
                p{
                    margin: 0;
                    color: $calendar-weekday-color;
                    font-size: 14px;
                }
            }
            .content_box{
                padding: 30px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 78px;
                overflow-y: auto;
                .input_box{
                    margin-bottom: 20px;
                    font-weight: 600;
                    position: relative;
                    label{
                        font-size: 14px;
                        font-weight: 700;
                        color: $default-color;
                        margin: 0 0 5px;
                        display: block;
                        span{
                            font-weight: normal;
                            color: $gray-color2;
                        }
                        .info_icon{
                            margin-left: 5px;
                            cursor: pointer;
                            color: $nav-color;
                            font-size: 16px;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                    .MuiFormControlLabel-label{
                        margin-left: 10px;
                        font-size: 14px;
                        font-weight: 700;
                        color: $default-color;
                    }
                }
            }
        }
        @media(max-width: 1199px){
            .expenses_sidebar{
                width: 40%;
            }
            .expenses_content{
                width: 60%;
            }
        }
        @media(max-width: 767px){
            display: block;
            position: relative;
            bottom: 0;
            .expenses_sidebar{
                width: 100%;
                border-right: 0;
                border-bottom: 1px solid $default-border;
                .search_box{
                    padding: 10px;
                }
                .expenses_list_holder{
                    position: relative;
                    top: 0;
                    height: 250px;
                    overflow-y: auto;
                    .expenses_list{
                        padding: 5px 10px;
                    }
                }
            }
            .expenses_content{
                width: 100%;
                .title_box{
                    padding: 10px 15px;
                    h4{
                        font-size: 16px;
                    }
                }
                .content_box{
                    padding: 15px;
                    position: relative;
                    top: 0;
                    margin-bottom: -50px;
                }
            }
        }
    }
}