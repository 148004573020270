@import '../../../assets/scss/variables';
.payments-page{
    .content_holder{  
        .info_box{
            display: flex;
            gap: 15px;
            border: 1px solid$info-box-border;
            border-radius: 5px;
            background-color: $info-box-bg;
            margin: 20px 0;
            padding: 20px;
            p{
                font-size: 12px;
                font-weight: 600;
                margin: 0;
            }
        }
        .container-box{
            .box_header{
                padding: 15px 25px;
                border-bottom: 1px solid $default-border;
                .title_box{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    margin: 10px 0;
                    h4{
                        font-size: 24px;
                        font-weight: 700;
                        color: $title-color;
                        margin: 0;
                    }
                    a{
                        color: $nav-color;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
                .flx-box{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    flex-wrap: wrap;
                    .search-holder{
                        width: 245px;
                    }
                }
            }
            .table-holder{
                .table{
                    table-layout: fixed;
                    margin: 0;
                    thead{
                        tr{
                            background-color: $table-thead-bg2;
                            th{
                                border: 1px solid $default-border;
                                height: 40px;
                                border-width: 0 0 1px;
                                vertical-align: middle;
                                text-align: left;
                                font-size: 12px;
                                text-transform: uppercase;
                                color: $gray-color2;
                                font-weight: 600;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 100px;
                                .sort_icon{
                                    font-size: 18px;
                                    margin-left: 5px;
                                    color: $nav-color;
                                    cursor: pointer;
                                    &:hover{
                                        color: $default-color;
                                    }
                                }
                                .info_icon{
                                    color: $nav-color;
                                    font-size: 16px;
                                    position: relative;
                                    top: 2px;
                                    margin-left: 5px;
                                    &:hover{
                                        color: $primary-color;
                                    }
                                }
                            }
                            th:first-child {
                                padding-left: 25px;
                                width: 150px;
                            }
                            th:nth-child(2){
                                width: 200px;
                            }
                            th:last-child{
                                padding-right: 25px;
                                width: 90px;
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                vertical-align: middle;
                                font-size: 14px;
                                font-weight: 600;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 100px;
                                .edit_btn{
                                    color: $nav-color;
                                    font-size: 28px;
                                    padding: 5px;
                                    &:hover{
                                        color: $primary-color;
                                    }
                                    .MuiSvgIcon-root{
                                        font-size: 28px;
                                    }
                                }
                            }
                            td:first-child {
                                padding-left: 25px;
                                width: 150px;
                            }
                            td:nth-child(2){
                                width: 200px;
                            }
                            td:last-child{
                                padding-right: 25px;
                                width: 90px;
                            }
                        }
                    }
                }
            }
        }
        
        @media(max-width: 767px){
            .container-box{
                .box_header{
                    .title_box{
                        h4{
                            font-size: 16px;
                        }
                        a{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}