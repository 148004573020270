@import '../../../../../assets/scss/variables';
.tasks_tab_holder{
    .status_tabs_holder{
        .status_tabs{
            margin-bottom: 20px;
            .box{
                height: 100%;
                width: 100%;
                line-height: normal;
                padding: 20px;
                border: 1px solid $default-border;
                box-shadow: 0 2px 4px 0 $default-shadow;
                background-color: $white-color;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;
                gap: 20px;
                .icon_holder{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: $red-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $white-color;
                    font-size: 24px;
                    .MuiSvgIcon-root{
                        font-size: 24px;
                    }
                }
                h6{
                    margin: 0;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-size: 14px;
                    color: $title-color;
                }
                h1{
                    margin: 0;
                    font-weight: 800;
                    text-transform: capitalize;
                    font-size: 36px;
                    color: $title-color;
                }
                p{
                    margin: 0;
                    color: $gray-color2;
                    font-size: 11px;
                    text-transform: none;
                }
            }
            .box.all{
                transition: .5s all;
                .icon_holder{
                    background-color: $status_all_blue;
                }
                &:hover, &:focus{
                    background-color: $status_all_blue;
                    border-color: $status_all_blue;
                    .icon_holder{
                        background-color: $white-color;
                        color: $status_all_blue;
                    }
                    h6{
                        color: $white-color;
                    }
                    h1{
                        color: $white-color;
                    }
                    p{
                        color: $white-color;
                    }
                }
                
            }
            .all.active{
                background-color: $status_all_blue;
                border-color: $status_all_blue;
                .icon_holder{
                    background-color: $white-color;
                    color: $status_all_blue;
                }
                h6{
                    color: $white-color;
                }
                h1{
                    color: $white-color;
                }
                p{
                    color: $white-color;
                }
            }
            .box.pending{
                transition: .5s all;
                .icon_holder{
                    background-color: $red-color;
                }
                &:hover, &:focus{
                    background-color: $red-color;
                    border-color: $red-color;
                    .icon_holder{
                        background-color: $white-color;
                        color: $red-color;
                    }
                    h6{
                        color: $white-color;
                    }
                    h1{
                        color: $white-color;
                    }
                    p{
                        color: $white-color;
                    }
                }
                
            }
            .pending.active{
                background-color: $red-color;
                border-color: $red-color;
                .icon_holder{
                    background-color: $white-color;
                    color: $red-color;
                }
                h6{
                    color: $white-color;
                }
                h1{
                    color: $white-color;
                }
                p{
                    color: $white-color;
                }
            }
            .box.past-due{
                transition: .5s all;
                .icon_holder{
                    background-color: $status_pastdue;
                }
                &:hover, &:focus{
                    background-color: $status_pastdue;
                    border-color: $status_pastdue;
                    .icon_holder{
                        background-color: $white-color;
                        color: $status_pastdue;
                    }
                    h6{
                        color: $white-color;
                    }
                    h1{
                        color: $white-color;
                    }
                    p{
                        color: $white-color;
                    }
                }
                
            }
            .past-due.active{
                background-color: $status_pastdue;
                border-color: $status_pastdue;
                .icon_holder{
                    background-color: $white-color;
                    color: $status_pastdue;
                }
                h6{
                    color: $white-color;
                }
                h1{
                    color: $white-color;
                }
                p{
                    color: $white-color;
                }
            }
            .box.completed{
                transition: .5s all;
                .icon_holder{
                    background-color: $green-color;
                }
                &:hover, &:focus{
                    background-color: $green-color;
                    border-color: $green-color;
                    .icon_holder{
                        background-color: $white-color;
                        color: $green-color;
                    }
                    h6{
                        color: $white-color;
                    }
                    h1{
                        color: $white-color;
                    }
                    p{
                        color: $white-color;
                    }
                }
                
            }
            .completed.active{
                background-color: $green-color;
                border-color: $green-color;
                .icon_holder{
                    background-color: $white-color;
                    color: $green-color;
                }
                h6{
                    color: $white-color;
                }
                h1{
                    color: $white-color;
                }
                p{
                    color: $white-color;
                }
            }
        }
    }
    .container-box{
        border: 1px solid $default-border;
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 2px 4px 0 $default-shadow;
        position: relative;
        .box_header{
            padding: 15px 20px;
            border-bottom: 1px solid $default-border;
            .flx-box{
                display: flex;
                gap: 10px;
                margin: 5px 0;
                align-items: center;
                flex-wrap: wrap;
                .search-holder{
                    width: 245px;
                }
                .btn_circle{
                    border-radius: 50%;
                    border: 1px solid $chip-btn-border;
                    width: 26px;
                    height: 26px;
                    padding: 0;
                    font-weight: 700;
                    font-size: 12px;
                }
                .MuiChip-root{
                    border: 1px solid $chip-btn-border;
                    border-radius: 16px;
                    background-color: $white-color;
                    height: 26px;
                    color: $chip-btn-color;
                    .MuiChip-deleteIcon {
                        color: $chip-btn-color;
                        font-size: 18px;
                    }
                }
            }
        }
        .table-holder{
            .table{
                table-layout: fixed;
                margin: 0;
                thead{
                    tr{
                        background-color: $table-thead-bg2;
                        th{
                            border: 1px solid $default-border;
                            height: 40px;
                            border-width: 0 0 1px;
                            vertical-align: middle;
                            text-align: left;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $gray-color2;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 150px;
                            .sort_icon{
                                font-size: 18px;
                                margin-left: 5px;
                                color: $nav-color;
                                cursor: pointer;
                                &:hover{
                                    color: $default-color;
                                }
                            }
                        }
                        th:first-child {
                            padding-left: 20px;
                        }
                        th:nth-child(5){
                            width: 100px;
                        }
                        th:last-child{
                            border-right: 20px;
                            width: 90px;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            vertical-align: middle;
                            font-size: 14px;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 150px;
                            .avatar_flx_box{
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                .MuiAvatar-root{
                                    background-color: rgba(85,174,42,0.08);
                                    color: $green-color;
                                    border: 1px solid $green-color;
                                    width: 32px;
                                    height: 32px;
                                    font-size: 14px;
                                }
                                .flx_icon_box{
                                    display: flex;
                                    gap: 15px;
                                    align-items: center;
                                    div{
                                        display: flex;
                                        align-items: center;
                                        gap: 3px;
                                        span{
                                            font-weight: 600;
                                            font-size: 12px;
                                        }
                                        .MuiSvgIcon-root,i{
                                            color: $nav-color;
                                        }
                                    }
                                }
                            }
                            .pending-icon{
                                color: $red-color;
                                font-size: 11px;
                                position: relative;
                                top: -1px;
                                margin-right: 2px;
                            }
                            .pastdue-icon{
                                color: $status_pastdue;
                                font-size: 11px;
                                position: relative;
                                top: -1px;
                                margin-right: 2px;
                            }
                            .completed-icon{
                                color: $green-color;
                                font-size: 11px;
                                position: relative;
                                top: -1px;
                                margin-right: 2px;
                            }
                            .edit_btn{
                                color: $nav-color;
                                font-size: 28px;
                                padding: 5px;
                                &:hover{
                                    color: $primary-color;
                                }
                                .MuiSvgIcon-root{
                                    font-size: 28px;
                                }
                            }
                        }
                        td:first-child {
                            padding-left: 20px;
                        }
                        td:nth-child(5){
                            width: 100px;
                        }
                        td:last-child{
                            border-right: 20px;
                            width: 90px;
                        }
                    }
                }
            }
        }
    }
}