@import '../../../../../../assets/scss/variables';
.rental_gallery_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .dflt_dropdown_mui {
        .MuiButtonBase-root {
            justify-content: end;
        }
    }
    .gallery_holder{
        margin: 0 0 20px;
        .box{
            height: 100%;
            border: 1px solid $default-border;
            border-radius: 8px;
            background-color: $white-color;
            padding: 15px;
            .img_holder{
                position: relative;
                margin: 0 0 18px;
                img{
                    width: 100%;
                    border-radius: 8px;
                }
                .edit_btn{
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    z-index: 1;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    border: 1px solid rgba(154,167,183,0.59);
                    background-color: $white-color;
                    color: $primary-color;
                    opacity: 0;
                }
                .pin_primary_text{
                    position: absolute;
                    z-index: 1;
                    left: 10px;
                    bottom: 10px;
                    margin: 0;
                    background: rgba($color: $black-color, $alpha: 0.3);
                    border-radius: 2px;
                    font-size: 11px;
                    color: $white-color;
                    text-transform: uppercase;
                    font-weight: 700;
                    padding-right: 10px;
                    display: none;
                    .set_primary_btn{
                        margin: 0;
                        color: $white-color;
                        font-size: 12px;
                        box-shadow: none;
                        border-radius: 2px;
                        background: $green-color;
                        padding: 3px 5px;
                        margin-right: 5px;
                    }
                }
            }
            .captions{
                text-align: center;
                h4{
                    font-size: 14px;
                    font-weight: 700;
                    margin: 0;
                }
                p{
                    color: #899098;
                    font-size: 14px;
                    margin: 0;
                }
            }
            &:hover{
                .img_holder{
                    .edit_btn{
                        opacity: 1;
                    }
                }
            }
        }
        .box.primary{
            .img_holder{
                .edit_btn{
                    opacity: 1;
                }
                .pin_primary_text{
                    display: block;
                }
            }
        }
    }
}