@import '../../../../assets/scss/variables';
.booking_popup{
    .dialog_title_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $default-border2;
        padding: 20px 15px 20px 30px;
        h4{
            color: $title-color;
            font-size: 21px;
            font-weight: 700;
            margin: 0;
        }
        p{
            font-size: 12px;
            margin: 0;
            color: $gray-color2;
            img{
                max-height: 12px;
                margin-left: 5px;
            }
        }
        .close_btn{
            color: $nav-color;
            &:hover{
                color: $default-color;
            }
        }
    }
    .dialog_content_box{
        padding: 20px 30px !important;
        .b_flx_box{
            display: flex;
            gap: 15px;
            margin: 0 0 15px;
            @media(max-width: 575px){
                flex-wrap: wrap;
                gap: 10px;
            }
            .b_icon_holder{
                min-width: 30px;
                .b_icon{
                    color: $blue-color;
                    font-size: 24px;
                }
            }
            .b_content_holder{
                h4{
                    font-weight: 700;
                    font-size: 14px;
                    color: $title-color;
                    margin: 0 0 10px;
                }
                .row_clm{
                    font-size: 14px;
                    color: $title-color;
                    margin: 0 0 5px;
                    label{
                        color: $gray-color2;
                        font-weight: normal;
                    }
                    p{
                        font-weight: 600;
                        color: $default-color;
                        margin: 0 0 10px;
                    }
                    .paid{
                        background-color: $green-color;
                        color: $white-color;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding: 1px 15px;
                        min-width: 75px;
                        font-size: 12px;
                        text-align: center;
                        margin-left: 5px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}