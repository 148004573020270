@import '../../../../../../assets/scss/variables';
.cleaning_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .inputbox_holder{
        .container-box{
            border: 1px solid $default-border;
            border-radius: 5px;
            background-color: $white-color;
            box-shadow: 0 -2px 4px 0 $default-shadow;
            padding: 20px 20px 0;
            margin: 0 0 25px;
            .box_title{
                color: $gray-color2;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                margin: 0 0 20px;
            }
            .input_box{
                margin-bottom: 20px;
                font-weight: 600;
                position: relative;
                label{
                    font-size: 14px;
                    font-weight: 700;
                    color: $title-color;
                    margin: 0 0 5px;
                    display: block;
                }
                .MuiFormControlLabel-label{
                    color: $chip-btn-color;
                    font-size: 14px;
                }
                .MuiCheckbox-root{
                    padding: 5px;
                    left: -5px;
                    margin: 0;
                }
                .add_checklist{
                    .MuiInputBase-multiline{
                        align-items: baseline;
                        padding: 5px 0 0;
                        gap: 8px;
                        .MuiSvgIcon-root{
                            color: rgba(0, 0, 0, 0.3);
                        }
                    }
                    .MuiOutlinedInput-root fieldset {
                        border: 0;
                    }
                }
                .chip_holder{
                    position: absolute;
                    top: 29px;
                    left: 15px;
                    right: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: flex;
                    gap: 5px;
                    .MuiChip-label{
                        color: $gray-color2;
                    }
                }
            }
            p{
                margin: 0;
                color: $gray-color;
                font-size: 12px;
            }
        }
    }
}