@import '../../../../../../assets/scss/variables';
.manage_reviews_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .container-box{
        border: 1px solid $default-border;
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 -2px 4px 0 $default-shadow;
        position: relative;
        .box_header{
            padding: 15px 20px;
            border-bottom: 1px solid $default-border;
            .flx-box{
                display: flex;
                gap: 10px;
                margin: 5px 0;
                align-items: center;
                flex-wrap: wrap;
                .search-holder{
                    width: 245px;
                }
                .btn_circle{
                    border-radius: 50%;
                    border: 1px solid $chip-btn-border;
                    width: 26px;
                    height: 26px;
                    padding: 0;
                    font-weight: 700;
                    font-size: 12px;
                }
                .MuiChip-root{
                    border: 1px solid $chip-btn-border;
                    border-radius: 16px;
                    background-color: $white-color;
                    height: 26px;
                    color: $chip-btn-color;
                    .MuiChip-deleteIcon {
                        color: $chip-btn-color;
                        font-size: 18px;
                    }
                }
            }
        }
        .table_tabs{
            background-color: $white-color;
            padding: 0 20px;
            .MuiTab-root{
                font-weight: 700;
                color: $chip-btn-color;
                font-size: 14px;
                padding: 12px 30px;
                text-transform: capitalize;
            }
            .Mui-selected{
                color: $overlay-sidebar-tab-selected-color;
            }
            .MuiTabs-indicator{
                height: 3px;
            }
        }
        .table-holder{
            border-top: 1px solid $default-border;
            .table{
                table-layout: fixed;
                margin: 0;
                thead{
                    tr{
                        background-color: $table-thead-bg2;
                        th{
                            border: 1px solid $default-border;
                            height: 40px;
                            border-width: 0 0 1px;
                            vertical-align: middle;
                            text-align: left;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $gray-color2;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 100px;
                            .sort_icon{
                                font-size: 18px;
                                margin-left: 5px;
                                color: $nav-color;
                                cursor: pointer;
                                &:hover{
                                    color: $default-color;
                                }
                            }
                        }
                        th:nth-child(2){
                            width: 200px;
                        }
                        th:nth-child(3){
                            width: 375px;
                        }
                        th:nth-child(4){
                            width: 200px;
                        }
                        th:first-child {
                            padding-left: 20px;
                            width: 120px;
                        }
                        th:last-child{
                            border-right: 20px;
                            width: 90px;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            vertical-align: middle;
                            font-size: 14px;
                            font-weight: 600;
                            width: 130px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            .avatar_flx_box{
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                .MuiAvatar-root{
                                    background-color: rgba(85,174,42,0.08);
                                    color: $green-color;
                                    border: 1px solid $green-color;
                                    width: 32px;
                                    height: 32px;
                                    font-size: 14px;
                                }
                            }
                            .rating{
                                color: $rating-color;
                            }
                            .action_btn{
                                color: $primary-color
                            }
                        }
                        td:first-child {
                            padding-left: 20px;
                            width: 115px;
                            img{
                                max-width: 20px;
                                max-height: 20px;
                                margin-left: 15px;
                            }
                        }
                        td:last-child{
                            border-right: 20px;
                            width: 90px;
                        }
                    }
                }
            }
        }
    }
}