@import '../../../../../assets/scss/variables';
.inquiry_tab_holder{
    .inquiry_sidebar{
        .box{
            background: $white-color;
            border: 1px solid $default-border;
            margin: 0 0 10px;
            .MuiPaper-root{
                width: 100%;
                background-color: transparent;
                box-shadow: none;
                border-radius: 0;
                padding: 0;
                margin: 0;
                .MuiAccordionSummary-root{
                    flex-direction: row-reverse;
                    padding: 10px 15px;
                    min-height: auto;
                    .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded{
                        margin: 0;
                    }
                }
                .MuiAccordionSummary-root.Mui-expanded {
                    min-height: auto;
                }
                .MuiAccordionSummary-expandIconWrapper{
                    color: $calendar-weekday-color;
                }
                .MuiAccordionDetails-root{
                    border-top: 1px solid $default-border;
                    padding: 15px;
                }
            }
            .box_title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 700;
                font-size: 12px;
                text-transform: uppercase;
                color: $calendar-weekday-color;
                width: 100%;
                margin: 0;
                gap: 10px;
            }
            .flx_box{
                display: flex;
                gap: 10px;
                .avater_box{
                    img{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }
            }
            .title{
                color: $calendar-title;
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 15px;
                line-height: normal;
                label{
                    color: $inquiry_label_color;
                    font-size: 10px;
                    font-weight: 800;
                    margin: 0 5px 0 0;
                    border-radius: 4px;
                    text-transform: uppercase;
                    padding: 3px 10px;
                    background-color: $inquiry_label_bg;
                }
                img{
                    max-height: 12px;
                }
            }
            .txt_box{
                margin: 0 0 15px;
                label{
                    color: $chip-btn-color;
                    font-size: 12px;
                    font-weight: 700;
                    margin: 0;
                }
                p{
                    color: $calendar-title;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                }
            }
            .calendar_header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-transform: uppercase;
                color: $calendar-title;
                font-size: 14px;
                font-weight: 600;
                .MuiIconButton-root{
                    padding: 5px;
                    color: $nav-color;
                    &:hover{
                        color: $calendar-title;
                    }
                    .MuiSvgIcon-root{
                        font-size: 24px;
                    }
                }
            }
            .calendar_content{
                .weekdays{
                    margin: 0;
                    padding: 10px 0;
                    font-weight: 700;
                    font-size: 12px;
                    text-transform: uppercase;
                    li {
                        display: inline-block;
                        width: 14.28%;
                        color: $calendar-weekday-color;
                        text-align: center;
                    }
                }
                .days{
                    padding: 0;
                    margin: 0;
                    li {
                        list-style-type: none;
                        display: inline-block;
                        width: 14.28%;
                        text-align: center;
                        padding: 5px 0;
                        font-size:12px;
                        color: $calendar-weekday-color;
                        overflow: hidden;
                    }
                    li.disable {
                        color: $calendar-disable-text;
                        pointer-events: none;
                    }
                    li.has_event{
                        position: relative;
                        cursor: pointer;
                        span{
                            font-weight: 700;
                            color: $calendar-title;
                            position: relative;
                            z-index: 1;
                        }
                    }
                    li.has_event.bg_green{
                        background: $calendar-event-bg-green
                    }
                    li.has_event.bg_blue{
                        background: $calendar-event-bg-blue;
                    }
                    li.has_event.event_start::after{
                        content: "";
                        position: absolute;
                        transform: skewX(-30deg);
                        width: 55%;
                        left: -30%;
                        top: 0;
                        bottom: 0;
                        background: $white-color;
                    }
                    li.has_event.event_end::after{
                        content: "";
                        position: absolute;
                        transform: skewX(-30deg);
                        width: 55%;
                        right: -30%;
                        top: 0;
                        bottom: 0;
                        background: $white-color;
                    }
                }
            }
        }
        .box.has_padding{
            padding: 10px 15px;
        }
    }
    .inquiry_content{
        .search_box{
            padding-left: 55px;
            margin: 0 0 20px;
            .MuiOutlinedInput-root{
                font-weight: 600;
                height: 40px;
            }
        }
        .day_n_date{
            padding-left: 55px;
            margin: 0 0 15px;
            font-weight: 700;
            color: $calendar-weekday-color;
            text-transform: uppercase;
            font-size: 12px;
        }
        .loading_info{
            padding-left: 55px;
            margin: 10px 0 15px;
            font-weight: 700;
            color: $calendar-weekday-color;
            text-transform: uppercase;
            font-size: 14px;
            strong{
                color:$title-color;
            }
        }
        .inquiry_box{
            display: flex;
            gap: 20px;
            .avatar{
                height: 32px;
                width: 32px;
                min-height: 32px;
                min-width: 32px;
                border: 1px solid $inquiry_avatar-border;
                background-color: $white-color;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                img{
                    max-width: 15px;
                    max-height: 15px;
                }
                i, .MuiSvgIcon-root{
                    font-size: 20px;
                    color: $primary-color;
                }
            }
            .content_box{
                border: 1px solid $default-border;
                border-radius: 0 15px 15px 15px;
                background-color: $white-color;
                padding: 20px;
                width: 100%;
                margin: 0 0 10px;
                .head_flx_box{
                    display: flex;
                    justify-content: space-between;
                    gap: 15px;
                    margin: 0 0 10px;
                    h2{
                        color: $title-color;
                        font-size: 14px;
                        font-weight: 700;
                        margin: 0 0 5px;
                    }
                    h6{
                        color: $calendar-weekday-color;
                        font-weight: normal;
                        font-size: 12px;
                        margin: 0;
                    }
                    .unfold_more_btn{
                        border: 1px solid $green-color;
                        background-color: $inquiry_content_selected_bg;
                        color: $primary-color;
                        width: 30px;
                        height: 30px;
                    }
                }
                p{
                    margin: 0;
                    font-size: 14px;
                    color: #475059;
                }
            }
            .content_box.selected{
                border: 1px solid $green-color;
                background-color: $inquiry_content_selected_bg;
                p{
                    font-weight: 600;
                }
            }
        }
    }
}