@import '../../../assets/scss/variables';
.sidebar_holder{
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 80px;
    border-right: 1px solid $default-border;
    background: $white-color;
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }    
    .logo_holder{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-bottom: 1px solid $default-border;
        a{
            display: block;
            text-align: center;
            transition: all .3s;
            img{
                max-width: 44px;
            }
            &:hover{
                opacity: .8;
            }
        }
    }
    .nav_holder{
        padding: 0;
        position: absolute;
        inset: 80px 0 180px;
        overflow-y: auto;
        a{
            display: block;
            margin: 0 0 5px;
            button{
                width: 100%;
                border-radius: 0;
                font-size: 22px;
                padding: 15px;
                color: $nav-color;
                &:hover{
                    color: $primary-color;
                }
            }
        }
        a.active{
            button{
                color: $primary-color;
            }
        }
    }
    .nav_bottom_fxd{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid $default-border;
        text-align: center;
        padding: 0;
        z-index: 1;
        a{
            display: flex;
            justify-content: center;
            margin: 0 0 10px;
            button{
                width: 100%;
                border-radius: 0;
                font-size: 22px;
                padding: 15px;
                color: $nav-color;
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
}
.mobile_nav_holder{
    padding: 5px 10px;
    .toggle_btn{
        z-index: 1021;
    }
}
.mobile_menu_drawer{
    .MuiDrawer-paper{
        width: 280px;
        @media(max-width: 350px){
            width: 260px;
        }
    }
    .nav_header{
        background: $nav-profile-bg;
        padding: 20px;
        display: flex;
        gap: 15px;
        align-items: center;
        color: $white-color;
        .profile_box{
            button{
                background-color: $white-color;
                width: 22px;
                height: 22px;
                &:hover{
                    background-color: $white-color;
                }
            }
        }
        .name{
            font-weight: 700;
            font-size: 17px;
            margin: 0 0 2px;
        }
        .email{
            color: $white-color;
            font-size: 14px;
            margin: 0;
        }
    }
    .navbox{
        margin-top: 30px;
        a{
            display: flex;
            gap: 15px;
            align-items: center;
            padding: 10px 20px;
            color: $default-color;
            font-size: 17px;
            margin-bottom: 10px;
            font-weight: 600;
            i, .MuiSvgIcon-root{
                font-size: 22px;
                width: 25px;
                color: $nav-color;
                text-align: center;
            }
        }
        a.active{
            i, .MuiSvgIcon-root{
                color: $primary-color;
            }
            span{
                font-weight: 700;
            }
        }
        a.logout{
            margin-top: 50px;
            i,span{
                color: $light-red;
            }
        }
    }
}