@import '../../../../assets/scss/variables';
.add_rental_manually_overlay_holder{
    .MuiPaper-root{
        width: 85%;
        .btn_circle_close{
            position: fixed;
            top: 25px;
            left: calc(15% - 61px);
            z-index: 1;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            color: $white-color;
            background-color: $primary-color;
            &:hover{
                color: $white-color;
                background-color: $primary-color;
            }
        }
        .d_header{
            background-color: $white-color;
            height: 70px;
            border-bottom: 1px solid $default-border;
            display: flex;
            justify-content: space-between;
            padding: 10px 30px;
            align-items: center;
            z-index: 1;
            .title{
                font-weight: 700;
                color: $title-color;
                font-size: 21px;
            }
            .btn_holder{
                display: flex;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;
                .btn_mui_icon_outline {
                    height: 34px;
                    font-weight: 700;
                    padding: 5px;
                    min-width: 34px;
                    font-size: 14px;
                    border-color: $default-border;
                    color: $nav-color;
                    &:hover{
                        color: $primary-color;
                    }

                }
            }
        }
        .d_content{
            padding: 30px 30px 100px;
            background-color: $overlay-content-bg;
            position: absolute;
            left: 0;
            right: 0;
            top: 70px;
            bottom: 0;
            overflow-y: auto;
            .inputbox_holder{
                margin: 0 0 25px;
                .container-box{
                    border: 1px solid $default-border;
                    border-radius: 5px;
                    background-color: $white-color;
                    box-shadow: 0 -2px 4px 0 $default-shadow;
                    padding: 20px 20px 0;
                    height: 100%;
                    .box_title{
                        color: $gray-color2;
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin: 0 0 20px;
                    }
                    .input_box{
                        margin-bottom: 20px;
                        font-weight: 600;
                        position: relative;
                        label{
                            font-size: 14px;
                            font-weight: 700;
                            color: $title-color;
                            margin: 0 0 5px;
                            display: block;
                            span{
                                font-weight: normal;
                                color: $gray-color2;
                            }
                            .info_icon{
                                margin-left: 5px;
                                cursor: pointer;
                                color: $nav-color;
                                font-size: 16px;
                                &:hover{
                                    color: $primary-color;
                                }
                            }
                        }
                        .MuiCheckbox-root{
                            padding: 7px;
                        }
                    }
                }
            }
        }
        @media(max-width: 575px){
            width: 80%;
            .btn_circle_close{
                top: 20px;
                left: calc(20% - 51px);
            }
            .d_header{
                padding: 10px 20px;
                height: auto;
                flex-wrap: wrap;
                .title{
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .d_content{
                padding: 30px 20px 50px;
            }
        }
    }
}