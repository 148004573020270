@import '../../../../assets/scss/variables';
.login-page{
    display: flex;
    min-height: 100vh;
    .lhs_sec{
        width: 45%;
        padding: 50px 15px;
        .logo_holder{
            margin-bottom: 75px;
            img{
                max-height: 30px;
            }
        }
        .form_holder{
            margin: 0 0 75px;
            .input_box{
                margin-bottom: 20px;
                font-weight: 600;
                position: relative;
                label{
                    font-size: 14px;
                    font-weight: 700;
                    color: $title-color;
                    margin: 0 0 5px;
                }
                .MuiFormControlLabel-label {
                    font-weight: 600;
                }
                .Mui-checked ~ .MuiFormControlLabel-label {
                    font-weight: 700;
                }
                a{
                    color: $default-color;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
            .btn_holder{
                margin-bottom: 30px;
            }
            p{
                color: $gray-color2;
                font-size: 14px;
                a{
                    font-weight: 600;
                }

            }
        }
        .ftr_menus{
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            font-weight: 600;
            font-size: 14px;
            .active{
                color: $default-color;
                font-weight: 700;
            }
        }
    }
    .rhs_sec{
        width: 55%;
        background: $auth-gradient-bg;
        padding: 60px 15px;
        color: $white-color;
        .title{
            font-size: 36px;
            font-weight: 700;
            margin: 0 0 25px;
        }
        .subtitle{
            padding-left: 30px;
            font-weight: 800;
            font-size: 16px;
            text-transform: uppercase;
            margin: 0 0 20px;
        }
        .auth_carosel{
            display: flex;
            flex-wrap: wrap;
            .indicator_holder{
                order: -1;
                list-style: disc;
                margin: 0 0 30px;
                padding: 0 0 0 45px;
                text-align: left;
                display: block;
                .MuiIconButton-root{
                    background: none;
                    text-align: left;
                    font-weight: 700;
                    font-size: 16px;
                    color: $auth-slider-text-color;
                    margin: 0 0 15px;
                    position: relative;
                    display: block;
                    border-radius: 0;
                    &::after{
                        content: "\2022";
                        position: absolute;
                        left: -15px;
                        font-size: 16px;
                        top: 0;
                    }
                }
                .active{
                    background: none;
                    color: $white-color;
                }
            }
            .slide{
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    max-width: 100%;
                }
            }
        }
    }
    @media(max-width: 1199px){
        .rhs_sec{
            .title{
                font-size: 31px;
            }
        }
    }
    @media(max-width: 991px){
        .rhs_sec{
            .title{
                font-size: 24px;
            }
        }
    }
    @media(max-width: 767px){
        display: block;
        .lhs_sec{
            width: 100%;
            padding: 30px 20px;
            .logo_holder {
                margin-bottom: 30px;
                text-align: center;
            }
            .form_holder {
                margin: 0 0 50px;
                .btn_holder {
                    margin-bottom: 15px;
                }
                p {
                    text-align: center;
                }
            }
            .ftr_menus{
                justify-content: center;
            }
        }
        .rhs_sec{
            width: 100%;
            padding: 30px 20px;
            .title{
                font-size: 21px;
                text-align: center;
                margin: 0 0 15px;
            }
            .subtitle{
                padding-left: 0;
                font-size: 14px;
                margin: 0 0 15px;
            }
            .auth_carosel{
                .indicator_holder{
                    margin: 0 0 15px;
                    padding: 0 0 0 15px;
                    .MuiIconButton-root{
                        font-size: 14px;
                        margin: 0 0 10px;
                        &::after{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}