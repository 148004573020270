@import '../../../../../assets/scss/variables';
.channel_details_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .title_holder{
        margin: 0 0 10px;
        h4{
            color: $chip-btn-color;
            font-size: 14px;
            font-weight: 700;
            margin: 10px 0;
            text-transform: uppercase;
        }
        .flx-box{
            display: flex;
            align-items: center;
            gap: 10px;
            @media(max-width: 575px){
                flex-wrap: wrap;
                gap: 5px;
            }
        }
    }
    .container-box{
        border: 1px solid $default-border;
        border-radius: 5px;
        background-color: $white-color;
        padding: 20px 20px 0;
        margin: 0 0 25px;
        .box_title{
            color: $gray-color2;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 20px;
        }
        .input_box{
            margin-bottom: 20px;
            font-weight: 600;
            position: relative;
            label{
                font-size: 14px;
                font-weight: 700;
                color: $title-color;
                margin: 0 0 5px;
                display: block;
                span{
                    font-weight: normal;
                    color: $gray-color2;
                }
                .info_icon{
                    margin-left: 5px;
                    cursor: pointer;
                    color: $nav-color;
                    font-size: 16px;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
            .MuiCheckbox-root{
                padding: 7px;
            }
        }
        .table_tabs{
            background-color: $white-color;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom: 1px solid $rates_unavailable;
            .MuiTab-root{
                font-weight: 700;
                color: $chip-btn-color;
                font-size: 14px;
                padding: 12px 30px;
                text-transform: capitalize;
            }
            .Mui-selected{
                color: $overlay-sidebar-tab-selected-color;
            }
            .MuiTabs-indicator{
                height: 3px;
            }
        }
        .table-holder{
            .table{
                table-layout: fixed;
                margin: 0;
                border-color: $rates_unavailable;
                thead{
                    tr{
                        background-color: $table-thead-bg2;
                        th{
                            border: 1px solid $default-border;
                            height: 40px;
                            border-width: 0 0 1px;
                            vertical-align: middle;
                            text-align: left;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $gray-color2;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 100px;
                            .sort_icon{
                                font-size: 18px;
                                margin-left: 5px;
                                color: $nav-color;
                                cursor: pointer;
                                &:hover{
                                    color: $default-color;
                                }
                            }
                            .info_icon{
                                color: $nav-color;
                                font-size: 16px;
                                position: relative;
                                top: 2px;
                                margin-left: 5px;
                                &:hover{
                                    color: $primary-color;
                                }
                            }
                        }
                        th:first-child {
                            padding-left: 25px;
                            width: 220px;
                            border-top-left-radius: 5px;
                        }
                        th:last-child{
                            padding-right: 25px;
                            width: 350px;
                            border-top-right-radius: 5px;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            vertical-align: middle;
                            font-size: 14px;
                            font-weight: 600;
                            white-space: nowrap;
                            width: 100px;
                        }
                        td:first-child {
                            padding-left: 25px;
                            width: 220px;
                        }
                        td:last-child{
                            padding-right: 25px;
                            width: 350px;
                        }
                    }
                }
            }
            .table-channel-status{
                thead{
                    tr{
                        th:first-child {
                            width: 120px;
                        }
                        th:nth-child(2){
                            width: 150px;
                        }
                    }
                }
                tbody{
                    tr{
                        td:first-child{
                            width: 120px; 
                        }
                        td:nth-child(2){
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
    .container-box.table_holder_has_pagi{
        padding: 0;
    }
    .container-box.table_holder_has_no_pagi{
        padding: 0;
        .table-holder{
            .table{
                tbody{
                    tr{
                        td:first-child {
                            border-bottom-left-radius: 5px;
                        }
                        td:last-child{
                            border-bottom-right-radius: 5px;
                        }
                    }
                    tr:last-child{
                        td{
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}