@import '../../../../../../assets/scss/variables';
.rental_map_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .container-box{
        border: 1px solid $default-border;
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 -2px 4px 0 $default-shadow;
        padding: 20px;
        margin: 0 0 25px;
        h4{
            color: $calendar-title;
            font-size: 16px;
            font-weight: 700;
            margin: 0 0 30px;
        }
        p{
            font-size: 14px;
            line-height: 27px;
            margin: 0 0 30px;
        }
    }
}

.map-container{
    width: 100%;
    height: 500px;
    border-radius: 5px;
    @media(max-width: 991px){
        height: 400px;
    }
    @media(max-width: 767px){
        height: 300px;
    }
}