@import '../../../assets/scss/variables';
.statements-page{
    .content_holder{  
        .title-holder{
            margin-bottom: 10px;
            .title{
                display: flex;
                align-items: center;
                gap: 15px;
                margin: 10px 0;
                h4{
                    margin: 0;
                    font-size: 21px;
                    font-weight: 700;
                    color: $title-color;
                }
                a{
                    color: $gray-color;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        color: $primary-color;
                    }
                }
                @media(max-width: 767px){
                    gap: 10px;
                    h4{
                        font-size: 16px;
                    }
                    a{
                        font-size: 16px;
                    }
                }
            }
        }
        .container-box{
            .box_header{
                padding: 15px 20px;
                border-bottom: 1px solid $default-border;
                .flx-box{
                    display: flex;
                    gap: 10px;
                    margin: 5px 0;
                    align-items: center;
                    flex-wrap: wrap;
                    .search-holder{
                        width: 245px;
                    }
                    .btn_circle{
                        border-radius: 50%;
                        border: 1px solid $chip-btn-border;
                        width: 26px;
                        height: 26px;
                        padding: 0;
                        font-weight: 700;
                        font-size: 12px;
                    }
                    .MuiChip-root{
                        border: 1px solid $chip-btn-border;
                        border-radius: 16px;
                        background-color: $white-color;
                        height: 26px;
                        color: $chip-btn-color;
                        .MuiChip-deleteIcon {
                            color: $chip-btn-color;
                            font-size: 18px;
                        }
                    }
                }
            }
            .table-holder{
                .table{
                    table-layout: fixed;
                    margin: 0;
                    thead{
                        tr{
                            background-color: $table-thead-bg2;
                            th{
                                border: 1px solid $default-border;
                                height: 40px;
                                border-width: 0 0 1px;
                                vertical-align: middle;
                                text-align: left;
                                font-size: 12px;
                                text-transform: uppercase;
                                color: $gray-color2;
                                font-weight: 600;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 150px;
                                .sort_icon{
                                    font-size: 18px;
                                    margin-left: 5px;
                                    color: $nav-color;
                                    cursor: pointer;
                                    &:hover{
                                        color: $default-color;
                                    }
                                }
                            }
                            th:first-child {
                                padding-left: 20px;
                                width: 200px;
                            }
                            th:nth-child(2){
                                width: 250px;
                            }
                            th:nth-child(3){
                                width: 200px;
                            }
                            th:last-child{
                                border-right: 20px;
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                vertical-align: middle;
                                font-size: 14px;
                                font-weight: 600;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 150px;
                                .d_flx_rental_box{
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;
                                    .rental_box{
                                        display: flex;
                                        gap: 12px;
                                        align-items: center;
                                        .img_holder{
                                            img{
                                                width: 32px;
                                                height: 32px;
                                                border-radius: 50%;
                                            }
                                        }
                                        .rental_dsc{
                                            h4{
                                                font-size: 14px;
                                                font-weight: 600;
                                                margin: 0 0 2px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                max-width: 200px;
                                            }
                                        }
                                    }
                                }
                            }
                            td:first-child {
                                padding-left: 20px;
                                width: 200px;
                            }
                            td:nth-child(2){
                                width: 250px;
                            }
                            td:nth-child(3){
                                width: 200px;
                            }
                            td:last-child{
                                border-right: 20px;
                            }
                        }
                        tr.selected_row{
                            background-color: $table-thead-bg;
                            td{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
}