@import '../../../../../../assets/scss/variables';
.rates_calendar_tab_holder{ 
    .title-holder{
        margin: 0 0 15px;
        .title{
            h4{
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 0 0 5px;
                color: $chip-btn-color;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                a{
                    color: $rates-title-icon-color;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        color: $primary-color;
                    }
                }
                @media(max-width: 767px){
                    gap: 10px;
                    a{
                        font-size: 16px;
                    }
                }
            }
            p{
                font-size: 12px;
                font-weight: 600;
                color: $chip-btn-color;
                margin: 0;
            }
        }
        .flx-box{
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 0 0 10px;
            .btn_mui_dflt_outline, .icon_btn_mui_dflt_outline{
                border-color: $rates_unavailable;
            }
            @media(max-width: 575px){
                flex-wrap: wrap;
                gap: 5px;
            }
        }
    }
    .container-box{
        border-radius: 5px;
        border: 1px solid $rates_unavailable;
        background-color: $white-color;
        .table-holder{
            .table{
                margin: 0;
                border-color: $rates_unavailable;
                table-layout: fixed;
                thead{
                    tr{
                        th{
                            font-weight: 700;
                            font-size: 12px;
                            text-align: center;
                            text-transform: uppercase;
                            width: 140px;
                        }
                        th:first-child{
                            border-left: 0;
                            border-top-left-radius: 5px;
                        }
                        th:last-child{
                            border-right: 0;
                            border-top-right-radius: 5px;
                        }
                    }
                    tr:first-child{
                        border-top: 0;
                    }
                }
                tbody{
                    tr{
                        td{
                            padding: 0;
                            width: 140px;
                            height: 150px;
                            .date{
                                color: $chip-btn-color;
                                font-weight: 700;
                                font-size: 14px;
                            }
                        }
                        .unavailable{
                            background: url('../../../../../../assets/images/rates_calendar_overlay_td_bg_1.png') no-repeat;
                            background-size: 100% 100%;
                        }
                        .unavailable_bg_green{
                            background: url('../../../../../../assets/images/rates_calendar_overlay_td_bg_2.png') no-repeat;
                            background-size: 100% 100%;
                        }
                        .td_content{
                            border: 1px solid $rates-table-border;
                            background-color: $rates-td-default-bg;
                            padding: 10px;
                            height: 100%;
                            .td_row{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font-size: 12px;
                                font-weight: 600;
                                color: $chip-btn-color;
                                margin: 0 0 10px;
                                strong{
                                    color: $rates_status_color;
                                    font-size: 11px;
                                    font-weight: 800;
                                }
                                .btn_more{
                                    font-weight: 700;
                                    color: $primary-color;
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                                .clr_blue{
                                    color: $rates-clr_blue;
                                    strong{
                                        color: $rates-clr_blue;
                                    }
                                }
                                .clr_red{
                                    color: $rates_exceeded_color;
                                    strong{
                                        color: $rates_exceeded_color;
                                    }
                                }
                            }
                            .td_row:last-child{
                                margin: 0;
                            }
                        }
                        .td_content.bg_dark_blue{
                            border: 1px solid $rates-clr_dark_blue_border;
                            background-color: $rates-clr_dark_blue;
                        }
                        .td_content.bg_light_blue{
                            border: 1px solid $rates-clr_light_blue_border;
                            background-color: $rates-table-border;
                        }
                        td:first-child{
                            border-left: 0;
                            border-top-left-radius: 5px;
                        }
                        td:last-child{
                            border-right: 0;
                            border-top-right-radius: 5px;
                        }
                        .unavailable{
                            padding: 10px;
                        }
                    }
                    .closing_row{
                        td{
                            height: auto;
                            padding: 5px 10px;
                            font-weight: 700;
                            color: $chip-btn-color;
                        }
                    }
                    tr:last-child{
                        border-bottom: 0;
                    }

                }
            }
        }
    }
}
.calendar_popper_holder{
    .header_box{
        padding: 7.5px 15px;
        border-radius: 5px 5px 0 0;
        font-size: 16px;
        color: $title-color;
        font-weight: 700;
    }
    .content_box{
        padding: 0 15px 20px;
        font-size: 12px;
        color: $default-color;
        .td_row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 600;
            color: $chip-btn-color;
            margin: 0 0 5px;
            strong{
                color: $rates_status_color;
                font-size: 11px;
                font-weight: 800;
            }
            .btn_more{
                font-weight: 700;
                color: $primary-color;
                text-decoration: underline;
                cursor: pointer;
            }
            .clr_blue{
                color: $rates-clr_blue;
                strong{
                    color: $rates-clr_blue;
                }
            }
            .clr_red{
                color: $rates_exceeded_color;
                strong{
                    color: $rates_exceeded_color;
                }
            }
            img{
                max-width: 16px;
            }
        }
        .td_row:last-child{
            margin: 0;
        }
    }
}