@import '../../../../assets/scss/variables';
.statement_overlay_holder{
    .MuiPaper-root{
        width: 85%;
        .btn_circle_close{
            position: fixed;
            top: 25px;
            left: calc(15% - 61px);
            z-index: 1;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            color: $white-color;
            background-color: $primary-color;
            &:hover{
                color: $white-color;
                background-color: $primary-color;
            }
        }
        .d_header{
            background-color: $white-color;
            height: 70px;
            border-bottom: 1px solid $default-border;
            display: flex;
            justify-content: space-between;
            padding: 10px 30px;
            align-items: center;
            z-index: 1;
            .title{
                font-weight: 700;
                color: $title-color;
                font-size: 21px;
            }
            .btn_holder{
                display: flex;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;
                .btn_mui_icon_outline {
                    height: 34px;
                    font-weight: 700;
                    padding: 5px;
                    min-width: 34px;
                    font-size: 14px;
                    border-color: $default-border;
                    color: $nav-color;
                    &:hover{
                        color: $primary-color;
                    }

                }
            }
        }
        .d_content{
            padding: 15px 30px 100px;
            background-color: $overlay-content-bg;
            position: absolute;
            left: 0;
            right: 0;
            top: 70px;
            bottom: 0;
            overflow-y: auto;
            .back_to_pev_page{
                margin: 15px 0 30px;
                a{
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 800;
                    .MuiSvgIcon-root, i{
                        margin-right: 8px;
                    }
                }
            }
            .flx_box_holder{
                margin: 0 0 15px;
                .box_holder{
                    margin: 0 0 20px;
                    .box{
                        display: flex;
                        gap: 20px;
                        border: 1px solid $default-border;
                        border-radius: 5px;
                        background-color: $white-color;
                        box-shadow: 0 2px 4px 0 $default-shadow;
                        padding: 20px;
                        height: 100%;
                        h4{
                            font-size: 14px;
                            font-weight: 600;
                            color: $title-color;
                            margin: 0 0 5px;
                        }
                        h2{
                            font-size: 24px;
                            font-weight: 800;
                            color: $title-color;
                            margin: 0 0 10px;
                        }
                        p{
                            color: $gray-color2;
                            font-size: 11px;
                            margin: 0;
                        }
                    }
                    .company_logo{
                        align-items: center;
                        justify-content: center;
                        img{
                            max-width: 100%;
                            max-height: 80px;
                        }
                    }
                }
            }
            .dsc_flx_box{
                margin: 0 0 30px;
                h6{
                    color: $gray-color2;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0 0 10px;
                    text-transform: uppercase;
                }
                .dsc_p{
                    color: $gray-color2;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 0 30px;
                    strong{
                        color: $title-color;
                        font-weight: 800;
                    }
                }
                .msg_p{
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 0 30px;
                    color: $default-color;
                }
            }
            .container-box{
                border: 1px solid $default-border;
                border-radius: 5px;
                background-color: $white-color;
                box-shadow: 0 -2px 4px 0 $default-shadow;
                position: relative;
                .box_header{
                    padding: 15px 20px;
                    border-bottom: 1px solid $default-border;
                    .flx-box{
                        display: flex;
                        gap: 10px;
                        margin: 5px 0;
                        align-items: center;
                        flex-wrap: wrap;
                        h4{
                            font-size: 18px;
                            font-weight: 700;
                            margin: 0;
                            color: $title-color;
                            span{
                                font-weight: 600;
                                color: $gray-color2;
                            }
                        }
                        .search-holder{
                            width: 245px;
                        }
                    }
                }
                .table-holder{
                    .table{
                        table-layout: fixed;
                        margin: 0;
                        thead{
                            tr{
                                background-color: $table-thead-bg2;
                                th{
                                    border: 1px solid $default-border;
                                    height: 40px;
                                    border-width: 0 0 1px;
                                    vertical-align: middle;
                                    text-align: left;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    color: $gray-color2;
                                    font-weight: 600;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    width: 150px;
                                    .sort_icon{
                                        font-size: 18px;
                                        margin-left: 5px;
                                        color: $nav-color;
                                        cursor: pointer;
                                        &:hover{
                                            color: $default-color;
                                        }
                                    }
                                }
                                th:first-child {
                                    padding-left: 20px;
                                    width: 200px;
                                }
                                th:nth-child(2){
                                    width: 250px;
                                }
                                th:nth-child(3){
                                    width: 200px;
                                }
                                th:last-child{
                                    border-right: 20px;
                                }
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    vertical-align: middle;
                                    font-size: 14px;
                                    font-weight: 600;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    width: 150px;
                                    .d_flx_rental_box{
                                        display: flex;
                                        gap: 10px;
                                        align-items: center;
                                        .rental_box{
                                            display: flex;
                                            gap: 12px;
                                            align-items: center;
                                            .icon_holder{
                                                img{
                                                    max-width: 20px;
                                                }
                                            }
                                            .img_holder{
                                                img{
                                                    width: 32px;
                                                    height: 32px;
                                                    border-radius: 6px;
                                                }
                                            }
                                            .rental_dsc{
                                                h4{
                                                    font-size: 14px;
                                                    font-weight: 600;
                                                    margin: 0 0 2px;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;
                                                    max-width: 200px;
                                                }
                                            }
                                        }
                                    }
                                    .flx_icon_box{
                                        display: flex;
                                        gap: 15px;
                                        align-items: center;
                                        div{
                                            display: flex;
                                            align-items: center;
                                            gap: 3px;
                                            span{
                                                font-weight: 600;
                                                font-size: 12px;
                                            }
                                            .MuiSvgIcon-root,i{
                                                color: $nav-color;
                                            }
                                        }
                                    }
                                }
                                td:first-child {
                                    padding-left: 20px;
                                    width: 200px;
                                }
                                td:nth-child(2){
                                    width: 250px;
                                }
                                td:nth-child(3){
                                    width: 200px;
                                }
                                td:last-child{
                                    border-right: 20px;
                                }
                            }
                            tr.selected_row{
                                background-color: $table-thead-bg;
                                td{
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media(max-width: 575px){
            width: 80%;
            .btn_circle_close{
                top: 20px;
                left: calc(20% - 51px);
            }
            .d_header{
                padding: 10px 20px;
                .title{
                    font-size: 18px;
                }
            }
            .d_content{
                padding: 15px 20px 50px;
            }
        }
    }
}