body,html {
    font-family: $default-font;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: $default-color;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: $default-bg;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none; 
    }    
    ::-webkit-scrollbar-thumb {
        background-color: $default-scroll-bar-color;
        -webkit-box-shadow: none;
        border: 0;
        border-radius: 10px; 
    }
    ::-webkit-scrollbar-thumb:hover{
        background-color: $default-scroll-bar-hvr-color; 
    }
    a{
        text-decoration: none;
        color: $anchor-color;
        transition: color .5s;
    }
    a:hover{
        color: $primary-color;
    }
    .full_height{
        min-height: 100%;
    }
    .page_holder{
        position: absolute;
        inset: 0 0 0 80px;
        overflow-y: auto;
        background: $default-bg;
        .header{
            background: $white-color;
            border-bottom: 1px solid $default-border;
            min-height: 80px;
            padding: 15px 40px;
            display: flex;
            align-items: center;
            gap: 15px;
            h4{
                margin: 0;
                font-size: 18px;
                font-weight: 700;
                color: $title-color;
            }
            a{
                color: $nav-color;
                font-size: 20px;
                display: flex;
                align-items: center;
                &:hover{
                    color: $primary-color;
                }
            }
        }
        .content_holder{
            padding: 20px 40px 50px;
            .container-box{
                border: 1px solid $default-border;
                border-radius: 5px;
                background-color: $white-color;
                box-shadow: 0 2px 4px 0 $default-shadow;
                position: relative;
                overflow: hidden;
            }
        }
        @media(max-width: 767px){
            inset: 0;
            .header{
                padding: 15px 20px 15px 65px;
                min-height: auto;
                gap: 10px;
                h4{
                    font-size: 16px;
                }
                a{
                    font-size: 16px;
                }
            }
            .header.has_searh_box{
                padding: 7.5px 20px 7.5px 65px;
            }
            .content_holder{
                padding: 10px 20px 30px;
            }
        }
    }
    .table-responsive {
        scroll-behavior: smooth;
    }
    .pagination_holder{
        margin: 0;
        padding: 15px;
        .displaying_info{
            color: $gray-color2;
            font-weight: 600;
            span{
                color: $title-color;
                text-decoration: underline;
            }
        }
        .cst_paginations{
            .icon_btn_mui_dflt_outline{
                box-shadow: none;
                padding: 5px;
                color: $primary-color;
            }
            .btn_mui_dflt_outline{
                box-shadow: none;
                color: $gray-color2;
                font-weight: 400;
            }
            button:disabled{
                color: $default-border;
            }
        }
        @media(max-width: 767px){
            text-align: center;
            margin: 0 -15px;
            .cst_paginations{
                margin-bottom: 10px;
            }
        }
    }
    .btn-reset-filter{
        height: 34px;
        color: $gray-color;
        &:hover{
            color: $primary-color;
        }
    }
    .btn-filter{
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        height: 34px;
    }
}

body.full_width_no_sidemenu{
    .sidebar_holder, .mobile_nav_holder{
        display: none;
    }
}