@import '../../../../assets/scss/variables';
.planning-calendar-page{
    .content_holder{  
        .title-holder{
            margin-bottom: 5px;
            .title{
                display: flex;
                align-items: center;
                gap: 15px;
                margin: 10px 0;
                h4{
                    margin: 0;
                    font-size: 21px;
                    font-weight: 700;
                    color: $title-color;
                }
                a{
                    color: $gray-color;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        color: $primary-color;
                    }
                }
                @media(max-width: 767px){
                    gap: 10px;
                    h4{
                        font-size: 16px;
                    }
                    a{
                        font-size: 16px;
                    }
                }
            }
            .btn-group-box{
                border: 1px solid $default-border;
                display: flex;
                align-items: center;
                border-radius: 4px;
                padding: 3px;
                margin: 10px 0;
                a{
                    margin: 0 0 0 10px;
                    &:first-child{
                        margin: 0;
                    }
                    button{
                        min-width: auto;
                        padding: 5px;
                        font-size: 18px;
                        border-radius: 3px;
                        color: $gray-color;
                    }
                    .active{
                        background-color: $white-color;
                        color: $primary-color;
                        border: 1px solid $default-border;
                    }
                    &:hover{
                        button{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .header-input-box{
            margin-bottom: 15px;
            .select_month_box_holder{
                max-width: 550px;
                margin: 0 0 10px;
                .select_months{
                    .MuiInputBase-root{
                        background: $white-color;
                        font-weight: 600;
                        padding: 0 50px 0 2px;
                        min-height: 34px;
                        &:hover{
                            .MuiOutlinedInput-notchedOutline{
                                border-color: $primary-color;
                            }
                        }
                        .MuiChip-root{
                            border: 1px solid $chip-btn-border;
                            border-radius: 16px;
                            background-color: $white-color;
                            height: 24px;
                            color: $chip-btn-color;
                            .MuiChip-deleteIcon {
                                color: $chip-btn-color;
                                font-size: 18px;
                            }
                        }
                    }
                    .MuiOutlinedInput-notchedOutline{
                        border-color: $default-border;
                        border-radius: 5px;
                    }
                    .Mui-focused .MuiOutlinedInput-notchedOutline{
                        border-color: $primary-color;
                    }
                    .MuiAutocomplete-input {
                        padding: 6px 4px 6px 6px;
                    }
                }
            }
            .flx-box{
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 0 0 10px;
                @media(max-width: 575px){
                    flex-wrap: wrap;
                    gap: 5px;
                }
            }
        }

        ::-webkit-scrollbar {
            display: none;
        }
        .scroll_btn_left{
            position: absolute;
            left: 230px;
            height: 50px;
            width: 20px;
            z-index: 3;
            color: $table-arrow-color;
            border-radius: 0;
            padding-left: 3px;
            background-color: $table-thead-bg;
            .MuiSvgIcon-root{
                position: relative;
                left: 5px;
            }
        }
        .scroll_btn_right{
            position: absolute;
            background-color: $table-thead-bg;
            right: 0;
            height: 50px;
            width: 20px;
            z-index: 3;
            color: $table-arrow-color;
            border-radius: 0;
        }
        @media(min-width: 1675px){
            .scroll_btn_right, .scroll_btn_left{
                display: none;
            }
        }
        .table-holder{
            .table{
                table-layout: fixed;
                margin: 0;
                tbody{
                    tr.thead{
                        th{
                            border: 1px solid $default-border;
                            position: sticky;
                            top: 0;
                            z-index: 2;
                            width: 40px;
                            height: 50px;
                            border-width: 0 1px 1px 0;
                            background: $table-thead-bg;
                            vertical-align: middle;
                            text-align: center;
                            font-size: 12px;
                            .month_name{
                                font-size: 14px;
                                font-weight: 700;
                                margin: 0;
                                text-transform: uppercase;
                            }
                            .day{
                                color: $gray-color2;
                                font-weight: 700;
                                display: block;
                            }
                            .date{
                                font-weight: 700;
                                display: block;
                            }
                        }
                        th:first-child {
                            position: sticky;
                            left: 0;
                            z-index: 3;
                            width: 230px;
                            border-right: 0;
                            background: $table-thead-bg;
                            padding: 8px 15px;
                            text-align: left;
                            &::after{
                                content: "";
                                position: absolute;
                                width: 1px;
                                background: $default-border;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }
                            
                        }
                        th:nth-child(2){
                            width: 60px;
                            padding-left: 20px;
                        }
                        th:last-child{
                            border-right: 0;
                            width: 60px;
                            padding-right: 20px;
                        }
                        @media(min-width: 1675px){
                            th:nth-child(2){
                                width: 40px;
                                padding-left: 8px;
                            }
                            th:last-child{
                                width: 40px;
                                padding-right: 8px;
                            }
                        }
                    }
                    tr{
                        td{
                            width: 40px;
                            height: 50px;  
                            border: 1px solid $default-border;
                            position: relative;
                            border-width: 0 1px 1px 0;
                            vertical-align: middle;
                            .night_stay{
                                position: absolute;
                                right: 5px;
                                top: 5px;
                                display: inline-flex;
                                align-items: center;
                                span{
                                    font-weight: 600;
                                    font-size: 12px;
                                }
                                .MuiSvgIcon-root,i{
                                    color: $nav-color;
                                }
                            }
                        }
                        td.bg_line{
                            background: url('../../../../assets/images/calendar-lines.png') no-repeat;
                            background-position: top;
                            background-size: cover;
                        }
                        td.rental_box_holder{
                            .rental_box{
                                display: flex;
                                gap: 12px;
                                align-items: center;
                                .img_holder{
                                    img{
                                        width: 32px;
                                        height: 32px;
                                        border-radius: 5px;
                                    }
                                }
                                .rental_dsc{
                                    h4{
                                        font-size: 14px;
                                        font-weight: 600;
                                        margin: 0 0 2px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        max-width: 160px;
                                    }
                                    .flx_icon_box{
                                        display: flex;
                                        gap: 15px;
                                        align-items: center;
                                        div{
                                            display: flex;
                                            align-items: center;
                                            gap: 3px;
                                            span{
                                                font-weight: 600;
                                                font-size: 12px;
                                            }
                                            .MuiSvgIcon-root,i{
                                                color: $nav-color;
                                            }
                                        }
                                    }
                                }
                            }
                            .booking_confirmed::after{
                                content: "";
                                position: absolute;
                                width: 3px;
                                background: $green-color;
                                left: 0;
                                top: 0;
                                bottom: 0;
                            }
                            .booking_pending::after{
                                content: "";
                                position: absolute;
                                width: 3px;
                                background: $yellow-color;
                                left: 0;
                                top: 0;
                                bottom: 0;
                            }
                            .booking_cancelled::after{
                                content: "";
                                position: absolute;
                                width: 3px;
                                background: $red-color-2;
                                left: 0;
                                top: 0;
                                bottom: 0;
                            }
                        }
                        td:first-child {
                            position: sticky;
                            left: 0;
                            z-index: 2;
                            width: 230px;
                            border-right: 0;
                            background: $white-color;
                            padding: 8px 15px;
                            &::after{
                                content: "";
                                position: absolute;
                                width: 1px;
                                background: $default-border;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }
                        }
                        td:nth-child(2){
                            width: 60px;
                            padding-left: 20px;
                        }
                        td:last-child{
                            border-right: 0;
                            width: 60px;
                            padding-right: 20px;
                        }
                        @media(min-width: 1675px){
                            td:nth-child(2){
                                width: 40px;
                                padding-left: 8px;
                            }
                            td:last-child{
                                width: 40px;
                                padding-right: 8px;
                            }
                        }
                    }
                    tr.blank_ftr{
                        td,th{
                            border-bottom: 0;
                            height: 80px;
                        }
                    }
                }
            }
            .event{
                white-space: nowrap;
                display: inline-flex;
                z-index: 1;
                align-items: center;
                gap: 5px;
                vertical-align: middle;
                position: relative;
                cursor: pointer;
                .left_skew_box{
                    transform: skew(-30deg);
                    height: 38px;
                    width: 25px;
                    border: 1px solid $event-skew-border;
                    background-color: $event-skew-border-2;
                }
                .event_dsc_holder{
                    transform: skew(-30deg);
                    border: 1px solid $nav-color;
                    background-color: $event-bg;
                    box-shadow: 0 2px 4px 0 $default-shadow;
                    height: 38px;
                    display: inline-flex;
                    align-items: center;
                    padding: 0 20px;
                    .event_dsc{
                        transform: skew(30deg);
                        display: inline-flex;
                        align-items: center;
                        gap: 5px;
                        font-size: 14px;
                        font-weight: 600;
                        img{
                            max-width: 20px;
                            max-height: 18px;
                            margin-right: 8px;
                        }
                        .sep_dot{
                            color: $gray-color;
                        }
                    }
                }
                .b_confirmed{
                    border-left: 7px solid $green-color;
                }
                .b_pending{
                    border-left: 7px solid $yellow-color;
                }
                .b_cancelled{
                    border-left: 7px solid $red-color;
                }
                .right_skew_box{
                    transform: skew(-30deg);
                    height: 38px;
                    width: 42px;
                    border: 1px solid $event-skew-border;
                    background-color: $event-skew-border-2;
                }
            }
        }
        @media(max-width: 575px){
            .scroll_btn_left{
                left: 0;
            }
            .table-holder{
                .table{
                    tbody{
                        tr.thead{
                            th{
                                position: relative;
                            }
                            th:first-child {
                                position: relative;
                                padding: 8px 15px 8px 20px;
                            }
                        }
                        tr{
                            td:first-child {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }
}