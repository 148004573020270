@import '../../../../assets/scss/variables';
.standard-calendar-page{
    .content_holder{  
        .title-holder{
            margin-bottom: 5px;
            .title{
                display: flex;
                align-items: center;
                gap: 15px;
                margin: 10px 0;
                h4{
                    margin: 0;
                    font-size: 21px;
                    font-weight: 700;
                    color: $title-color;
                }
                a{
                    color: $gray-color;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        color: $primary-color;
                    }
                }
                @media(max-width: 767px){
                    gap: 10px;
                    h4{
                        font-size: 16px;
                    }
                    a{
                        font-size: 16px;
                    }
                }
            }
            .btn-group-box{
                border: 1px solid $default-border;
                display: flex;
                align-items: center;
                border-radius: 4px;
                padding: 3px;
                margin: 10px 0;
                a{
                    margin: 0 0 0 10px;
                    &:first-child{
                        margin: 0;
                    }
                    button{
                        min-width: auto;
                        padding: 5px;
                        font-size: 18px;
                        border-radius: 3px;
                        color: $gray-color;
                    }
                    .active{
                        background-color: $white-color;
                        color: $primary-color;
                        border: 1px solid $default-border;
                    }
                    &:hover{
                        button{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .header-input-box{
            margin-bottom: 15px;
            .flx-box{
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 0 0 10px;
                .range_date_picker_holder{
                    display: flex;
                    align-items: center;
                    height: 34px;
                    border: 1px solid $default-border;
                    background-color: $white-color;
                    border-radius: 5px;
                    font-size: 14px;
                    padding: 0 0 0 5px;
                    font-weight: 600;
                    &:focus-within{
                        border-color: $primary-color;
                    }
                    .input_date{
                        width: 110px;
                        padding: 0 5px;
                        font-weight: 600;
                    }
                    span{
                        color: $gray-color2;
                    }
                    button{
                        width: 30px;
                        height: 30px;
                        color: $nav-color;
                        i{
                            font-size: 16px;
                        }
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
                .status_toggle_btn_grp{
                    background: $white-color;
                    border-color: $default-border;
                    border-radius: 5px;
                    height: 34px;
                    font-size: 12px;
                    text-transform: uppercase;
                    button{
                        font-weight: 700;
                        i{
                            margin-right: 5px;
                            display: none;
                        }
                    }
                    .Mui-selected{
                        background: $primary-color;
                        color: $white-color;
                        i{
                            display: inline-flex;
                        }
                    }
                }
                @media(max-width: 575px){
                    flex-wrap: wrap;
                    gap: 5px;
                }
            }
        }
        .weekly-table-holder{
            .weekly-table{
                table-layout: fixed;
                margin: 0;
                thead{
                    tr{
                        th{
                            border: 1px solid $default-border;
                            height: 45px;
                            border-width: 0 1px 1px 0;
                            background: $white-color;
                            vertical-align: middle;
                            text-align: center;
                            font-size: 14px;
                            width: 165px;
                            .day{
                                color: $gray-color2;
                                font-weight: 700;
                            }
                            .date{
                                font-weight: 700;
                                margin-left: 8px;
                            }
                        }
                        th:first-child {
                            width: 85px;
                            padding: 0 15px;
                        }
                        th:last-child{
                            border-right: 0;
                        }
                    }
                }
                tbody{
                    tr.all_day{
                        td{
                            height: 80px;
                        }
                        td:first-child {
                            border-bottom: 1px solid $default-border;
                        }
                    }
                    tr{
                        td{
                            height: 40px;  
                            border: 1px solid $default-border;
                            position: relative;
                            border-width: 0 1px 1px 0;
                            vertical-align: middle;
                            width: 165px;
                            .time{
                                position: relative;
                                top: 45%;
                            }
                        }
                        td:first-child {
                            width: 85px;
                            font-size: 12px;
                            text-align: right;
                            color: $gray-color2;
                            font-size: 12px;
                            font-weight: 600;
                            padding: 0 15px;
                            border-bottom: 0;
                        }
                        td:last-child{
                            border-right: 0;
                        }
                    }
                    tr:last-child{
                        td{
                            border-bottom: 0;
                        }
                    }
                }
            }
            .event{
                position: absolute;
                display: flex;
                justify-content: center;
                width: 100%;
                left: 0;
                top: 0;
                padding: 8px;
                .event_dsc_holder{
                    background: $white-color;
                    position: relative;
                    z-index: 1;
                    cursor: pointer;
                    padding: 15px;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid $nav-color;
                    min-height: 320px;
                    h4{
                        color: $title-color;
                        font-size: 16px;
                        font-weight: 700;
                        margin: 0 0 8px;
                    }
                    .flx_lbl_box{
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        margin: 0 0 15px;
                        .lable_status{
                            display: inline-block;
                            height: 18px;
                            text-align: center;
                            padding: 2px 10px;
                            font-size: 10px;
                            font-weight: 700;
                            text-transform: uppercase;
                            border-radius: 4px;
                        }
                        img{
                            height: 14px;
                        }
                    }
                    p{
                        color: $calendar-weekday-color;
                        font-size: 14px;
                        line-height: 21px;
                        .email{
                            color: $calendar-weekday-color;
                        }
                    }
                    .flx_icon_box{
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        margin: 0 0 10px;
                        div{
                            display: flex;
                            align-items: center;
                            gap: 3px;
                            span{
                                font-weight: 600;
                                font-size: 12px;
                            }
                            .MuiSvgIcon-root,i{
                                color: $nav-color;
                            }
                        }
                    }
                }
                .paid{
                    border-top: 5px solid $green-color;
                    .lable_status{
                        color: $green-color;
                        background-color:$paid-bg;
                    }
                }
                .unpaid{
                    border-top: 5px solid $light-red;
                    .lable_status{
                        color: $light-red;
                        background-color: $unpaid-bg;
                    }
                }
            }
        }
        .monthly-table-holder{
            .monthly-table{
                table-layout: fixed;
                margin: 0;
                thead{
                    tr{
                        th{
                            border: 1px solid $default-border;
                            height: 45px;
                            border-width: 0 1px 1px 0;
                            background: $white-color;
                            vertical-align: middle;
                            text-align: center;
                            font-size: 14px;
                            width: 130px;
                            .day{
                                color: $gray-color2;
                                font-weight: 700;
                            }
                        }
                        th:last-child{
                            border-right: 0;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            height: 95px;  
                            border: 1px solid $default-border;
                            position: relative;
                            border-width: 0 1px 1px 0;
                            vertical-align: top;
                            padding: 10px 15px;
                            width: 130px;
                            .date{
                                font-weight: 700;
                                color: $title-color;
                            }
                            .flx-icon-box-holder{
                                position: absolute;
                                left: 15px;
                                bottom: 10px;
                                .flx_icon_box{
                                    display: flex;
                                    gap: 15px;
                                    align-items: center;
                                    div{
                                        display: flex;
                                        align-items: center;
                                        gap: 3px;
                                        span{
                                            font-weight: 600;
                                            font-size: 12px;
                                        }
                                        .MuiSvgIcon-root,i{
                                            color: #668D9D;
                                        }
                                    }
                                }
                            }
                        }
                        td.disable{
                            pointer-events: none;
                            .date{
                                color:$nav-color;
                            }
                        }
                        td:last-child{
                            border-right: 0;
                        }
                    }
                    tr:last-child{
                        td{
                            border-bottom: 0;
                        }
                    }
                }
            }
            .event{
                white-space: nowrap;
                display: inline-flex;
                z-index: 1;
                align-items: center;
                gap: 5px;
                vertical-align: middle;
                position: absolute;
                height: 100%;
                top: 0;
                cursor: pointer;
                .left_skew_box{
                    transform: skew(-30deg);
                    height: 38px;
                    width: 25px;
                    border: 1px solid $event-skew-border;
                    background-color: $event-skew-border-2;
                }
                .event_dsc_holder{
                    transform: skew(-30deg);
                    border: 1px solid $nav-color;
                    background-color: $event-bg;
                    box-shadow: 0 2px 4px 0 $default-shadow;
                    height: 38px;
                    display: inline-flex;
                    align-items: center;
                    padding: 0 20px;
                    .event_dsc{
                        transform: skew(30deg);
                        display: inline-flex;
                        align-items: center;
                        gap: 5px;
                        font-size: 14px;
                        font-weight: 600;
                        img{
                            max-width: 20px;
                            max-height: 18px;
                            margin-right: 8px;
                        }
                        .sep_dot{
                            color: $gray-color;
                        }
                    }
                }
                .b_confirmed{
                    border-left: 7px solid $green-color;
                }
                .b_pending{
                    border-left: 7px solid $yellow-color;
                }
                .b_cancelled{
                    border-left: 7px solid $red-color;
                }
                .right_skew_box{
                    transform: skew(-30deg);
                    height: 38px;
                    width: 42px;
                    border: 1px solid $event-skew-border;
                    background-color: $event-skew-border-2;
                }
            }
        }
        @media(max-width: 575px){
            
        }
    }
}