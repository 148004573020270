@import '../../../../../assets/scss/variables';
.rental_details_tab_holder{
    .fxdside_tab{
        position: fixed;
        right: 0;
        top: 78px;
        width: 49px;
        bottom: 0;
        .MuiTab-root{
            margin: 0;
            padding: 0;
            min-width: 0;
            border-bottom: 1px solid $default-border;
            color: $gray-color;
            &:last-child{
                border-bottom: 0;
            }
            &:hover{
                color: $primary-color;
            }
        }
        .Mui-selected{
            background-color: $white-color;
            color: $primary-color;
        }
        .MuiTabs-indicator{
            height: 0;
            width: 0;
        }
    }
}