@import '../../../../assets/scss/variables';
.edit_hold_event_popup{
    .dialog_title_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $default-border2;
        padding: 20px 15px 20px 30px;
        h4{
            color: $title-color;
            font-size: 21px;
            font-weight: 700;
            margin: 0;
        }
        p{
            font-size: 12px;
            margin: 0;
            color: $gray-color2;
            img{
                max-height: 12px;
                margin-left: 5px;
            }
        }
        .close_btn{
            color: $nav-color;
            &:hover{
                color: $default-color;
            }
        }
    }
    .dialog_content_box{
        padding: 20px 30px !important;
        .input_box{
            margin-bottom: 20px;
            font-weight: 600;
            label{
                font-size: 14px;
                font-weight: 700;
                color: $title-color;
                margin: 0 0 5px;
            }
            .MuiInputBase-input{
                font-weight: 600;
            }
        }
    }
    .dialog_footer_box{
        display: block !important;
        padding: 15px 30px;
        border-top: 1px solid $default-border2;
        .flx-box{
            display: flex;
            gap: 10px;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}