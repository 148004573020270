@import '../../../../../../assets/scss/variables';
.rental_amenities_tab_holder{
    .title_holder{
        margin-bottom: 10px;
        .title{
            color: $chip-btn-color;
            font-size: 14px;
            font-weight: 700;
            margin: 10px 0;
            text-transform: uppercase;
        }
        .flx-box{
            display: flex;
            gap: 10px;
            margin-bottom: 10px;
            align-items: center;
            flex-wrap: wrap;
            .search-holder{
                width: 266px;
            }
        }
    }
    .container-box{
        border: 1px solid $default-border;
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 -2px 4px 0 $default-shadow;
        padding: 30px;
        margin: 0 0 25px;
        h4{
            color: $calendar-title;
            font-size: 16px;
            font-weight: 700;
            margin: 0 0 30px;
        }
        p{
            font-size: 14px;
            line-height: 27px;
            margin: 0 0 30px;
        }
    }
}