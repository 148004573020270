@import '../../../../../../assets/scss/variables';
.rental_tags_tab_holder{
    .title{
        color: $chip-btn-color;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    .container-box{
        border: 1px solid $default-border;
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 -2px 4px 0 $default-shadow;
        position: relative;
        padding: 30px;
        @media(max-width: 767px){
            padding: 15px;
        }
        h4{
            font-weight: 700;
            font-size: 14px;
            margin: 0 0 15px;
        }
        .MuiChip-outlined{
            border: 1px solid $default-border;
            border-radius: 5px;
            background-color: $event-bg;
            margin: 0 6px 6px 0;
            font-weight: 700;
            font-size: 12px;
            color: $default-color;
            text-transform: uppercase;
            height: 25px;
        }
        .info_box{
            display: flex;
            gap: 15px;
            border: 1px solid$info-box-border;
            border-radius: 5px;
            background-color: $info-box-bg;
            padding: 20px;
            p{
                font-size: 12px;
                font-weight: 600;
                margin: 0;
            }
        }
    }
}