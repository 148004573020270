@import '../../../../assets/scss/variables';
.create_booking_overlay_holder{
    .MuiPaper-root{
        width: 65%;
        background-color: $overlay-content-bg;
        .btn_circle_close{
            position: fixed;
            top: 25px;
            left: calc(35% - 61px);
            z-index: 1;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            color: $white-color;
            background-color: $primary-color;
            &:hover{
                color: $white-color;
                background-color: $primary-color;
            }
        }
        .d_header{
            background-color: $white-color;
            height: 70px;
            border-bottom: 1px solid $default-border;
            display: flex;
            justify-content: space-between;
            padding: 10px 30px;
            align-items: center;
            z-index: 1;
            .title{
                font-weight: 700;
                color: $title-color;
                font-size: 21px;
            }
            .btn_holder{
                display: flex;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;
                .btn_mui_icon_outline {
                    height: 34px;
                    font-weight: 700;
                    padding: 5px;
                    min-width: 34px;
                    font-size: 14px;
                    border-color: $default-border;
                    color: $nav-color;
                    &:hover{
                        color: $primary-color;
                    }

                }
            }
        }
        .d_content{
            padding: 30px 30px 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 70px;
            bottom: 0;
            overflow-y: auto;
            .MuiPaper-root{
                width: 100%;
            }
            .accordion_holder{
                position: relative;
                padding-left: 60px;
                min-height: 100%;
                padding-bottom: 100px;
                &::after{
                    content: "";
                    width: 1px;
                    background: $default-border;
                    position: absolute;
                    top: 50px;
                    bottom: 0;
                    left: 15px;
                }
                .MuiAccordion-rounded{
                    border: 2px solid $default-border;
                    border-radius: 5px;
                    background-color: $white-color;
                    box-shadow: 0 2px 4px 0 $default-shadow;
                    margin: 0 0 25px;
                    &::before{
                        height: 0;
                    }
                    .step-counter{
                        position: absolute;
                        left: -65px;
                        top: 25px;
                        height: 35px;
                        width: 35px;
                        border: 1.35px solid $default-border;
                        background-color: $white-color;
                        color: $gray-color2;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 35px;
                        text-align: center;
                        border-radius: 50%;
                        z-index: 1;
                    }
                    .MuiAccordionSummary-expandIconWrapper{
                        color: $nav-color;
                    }
                    .MuiAccordionSummary-root{
                        padding: 20px 30px;
                        .MuiAccordionSummary-content{
                            padding: 0;
                            margin: 0;
                            h4{
                                font-weight: 700;
                                font-size: 14px;
                                color: $title-color;
                                margin: 0 0 5px;
                            }
                            p{
                                color: $gray-color2;
                                font-size: 16px;
                                font-weight: 600;
                                margin: 0;
                            }
                        }
                    }
                    .MuiCollapse-wrapper{
                        border-top: 1px solid $default-border;
                        padding: 20px 30px;
                        .MuiAccordionDetails-root{
                            padding: 0;
                        }
                        .input_box{
                            margin-bottom: 20px;
                            font-weight: 600;
                            position: relative;
                            label{
                                font-size: 14px;
                                font-weight: 700;
                                color: $title-color;
                                margin: 0 0 5px;
                                span{
                                    font-weight: normal;
                                    color: $gray-color2;
                                }
                                .info_icon{
                                    margin-left: 5px;
                                    cursor: pointer;
                                    color: $nav-color;
                                    font-size: 16px;
                                    &:hover{
                                        color: $primary-color;
                                    }
                                }
                            }
                            .MuiInputBase-input{
                                font-weight: 600;
                            }
                            .select_rentals_chip{
                                .MuiInputBase-root{
                                    background: $white-color;
                                    font-weight: 600;
                                    padding: 0 50px 0 2px;
                                    min-height: 37.13px;
                                    &:hover{
                                        .MuiOutlinedInput-notchedOutline{
                                            border-color: $primary-color;
                                        }
                                    }
                                    .MuiChip-root{
                                        border-radius: 16px;
                                        background-color: $chip-bg-2;
                                        height: 24px;
                                        color: $gray-color2;
                                        font-weight: 600;
                                        max-width: 120px;
                                        .MuiChip-deleteIcon {
                                            color: $chip-btn-color;
                                            font-size: 18px;
                                        }
                                    }
                                }
                                .MuiOutlinedInput-notchedOutline{
                                    border-color: $default-border;
                                    border-radius: 5px;
                                }
                                .Mui-focused .MuiOutlinedInput-notchedOutline{
                                    border-color: $primary-color;
                                }
                                .MuiAutocomplete-input {
                                    padding: 6px 4px 6px 6px;
                                }
                            }
                            .add_tax_btn{
                                font-weight: 700;
                                text-transform: capitalize;
                                font-size: 14px;
                            }
                            .text_box{
                                border: 1px solid $default-border;
                                border-radius: 5px;
                                padding: 10px 20px;
                                min-height: 130px;
                                font-weight: normal;
                                color: $default-color;
                                line-height: 23px;
                                font-size: 14px;
                            }
                            .multiply_icon{
                                position: absolute;
                                left: -9px;
                                top: 88px;
                                font-size: 18px;
                                color: $nav-color;
                            }
                        }
                    }
                }
                .MuiAccordion-rounded.Mui-expanded{
                    .step-counter{
                        border: 1.35px solid $primary-color;
                        background-color: $primary-color;
                        color: $white-color;
                    }
                    .MuiAccordionSummary-root.Mui-expanded{
                        min-height: auto;
                    }
                    .MuiAccordionSummary-expandIconWrapper{
                        color: $primary-color;
                    }
                }
            }
        }
        @media(max-width: 1449px){
            width: 75%;
            .btn_circle_close{
                left: calc(25% - 61px);
            }
        }
        @media(max-width: 1199px){
            width: 85%;
            .btn_circle_close{
                left: calc(15% - 61px);
            }
        }
        @media(max-width: 575px){
            width: 80%;
            .btn_circle_close{
                top: 20px;
                left: calc(20% - 51px);
            }
            .d_header{
                padding: 10px 15px;
                height: auto;
                flex-wrap: wrap;
                .title{
                    font-size: 16px;
                    margin: 5px 0;
                }
            }
            .d_content{
                padding: 30px 15px 0;
                .accordion_holder{
                    padding-left: 0;
                    padding-bottom: 50px;
                    &::after{
                        width: 0;
                    }
                    .MuiAccordion-rounded{
                        margin: 0 0 15px;
                        &::before{
                            height: 0;
                        }
                        .step-counter{
                            left: -12px;
                            top: 15px;
                            height: 25px;
                            width: 25px;
                            font-size: 14px;
                            line-height: 25px;
                        }
                        .MuiAccordionSummary-root{
                            padding: 10px 15px;
                            .MuiAccordionSummary-content{
                                h4{
                                    font-size: 14px;
                                }
                                p{
                                    font-size: 12px;
                                }
                            }
                        }
                        .MuiCollapse-wrapper{
                            padding: 20px 15px;
                            .input_box{
                                margin-bottom: 15px;
                                label{
                                    font-size: 12px;
                                    .info_icon{
                                        font-size: 14px;
                                    }
                                }
                                .MuiInputBase-input {
                                    font-size: 12px;
                                }
                                .add_tax_btn{
                                    font-size: 12px;
                                }
                                .text_box{
                                    padding: 10px 15px;
                                    line-height: 20px;
                                    font-size: 12px;
                                }
                                .multiply_icon{
                                    left: 2px;
                                }
                            }
                        }
                    }
                    .MuiAccordion-rounded.Mui-expanded{
                        .step-counter{
                            border: 1.35px solid $primary-color;
                            background-color: $primary-color;
                            color: $white-color;
                        }
                        .MuiAccordionSummary-root.Mui-expanded{
                            min-height: auto;
                        }
                        .MuiAccordionSummary-expandIconWrapper{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}