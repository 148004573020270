@import '../../../assets/scss/variables';
.bookings-page{
    .content_holder{  
        .flx_box_holder{
            margin-top: 15px;
            .box_holder{
                margin: 0 0 20px;
                .box{
                    display: flex;
                    gap: 20px;
                    border: 1px solid $default-border;
                    border-radius: 5px;
                    background-color: $white-color;
                    box-shadow: 0 2px 4px 0 $default-shadow;
                    padding: 20px;
                    height: 100%;
                    h4{
                        font-size: 14px;
                        font-weight: 600;
                        color: $title-color;
                        margin: 0 0 5px;
                    }
                    h2{
                        font-size: 24px;
                        font-weight: 800;
                        color: $title-color;
                        margin: 0 0 10px;
                    }
                    p{
                        color: $gray-color2;
                        font-size: 11px;
                        margin: 0;
                    }
                }
            }
        }
        .container-box{
            .box_header{
                padding: 15px 20px;
                border-bottom: 1px solid $default-border;
                .flx-box{
                    display: flex;
                    gap: 10px;
                    margin: 5px 0;
                    align-items: center;
                    flex-wrap: wrap;
                    h4{
                        font-size: 24px;
                        font-weight: 700;
                        margin: 0;
                        color: $title-color;
                        span{
                            color: $gray-color2;
                        }
                    }
                    .search-holder{
                        width: 266px;
                    }
                }
            }
            .table-holder{
                .table{
                    table-layout: fixed;
                    margin: 0;
                    thead{
                        tr{
                            background-color: $table-thead-bg2;
                            th{
                                border: 1px solid $default-border;
                                height: 40px;
                                border-width: 0 0 1px;
                                vertical-align: middle;
                                text-align: left;
                                font-size: 12px;
                                text-transform: uppercase;
                                color: $gray-color2;
                                font-weight: 600;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 110px;
                                .sort_icon{
                                    font-size: 18px;
                                    margin-left: 5px;
                                    color: $nav-color;
                                    cursor: pointer;
                                    &:hover{
                                        color: $default-color;
                                    }
                                }
                            }
                            th:first-child {
                                padding-left: 20px;
                                width: 180px;
                            }
                            th:nth-child(3){
                                width: 120px;
                            }
                            th:nth-child(2){
                                width: 300px;
                            }
                            th:nth-child(4), th:nth-child(6){
                                width: 90px;
                            }
                            th:nth-child(5){
                                width: 120px;
                            }
                            th:last-child{
                                border-right: 20px;
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                vertical-align: middle;
                                font-size: 14px;
                                font-weight: 600;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 110px;
                                .d_flx_rental_box{
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;
                                    .rental_box{
                                        display: flex;
                                        gap: 12px;
                                        align-items: center;
                                        .icon_holder{
                                            img{
                                                max-width: 20px;
                                            }
                                        }
                                        .img_holder{
                                            img{
                                                width: 32px;
                                                height: 32px;
                                                border-radius: 6px;
                                            }
                                        }
                                        .rental_dsc{
                                            h4{
                                                font-size: 14px;
                                                font-weight: 600;
                                                margin: 0 0 2px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                max-width: 175px;
                                            }
                                        }
                                    }
                                }
                                .flx_icon_box{
                                    display: flex;
                                    gap: 15px;
                                    align-items: center;
                                    div{
                                        display: flex;
                                        align-items: center;
                                        gap: 3px;
                                        span{
                                            font-weight: 600;
                                            font-size: 12px;
                                        }
                                        .MuiSvgIcon-root,i{
                                            color: $nav-color;
                                        }
                                    }
                                }
                            }
                            td:first-child {
                                padding-left: 20px;
                                width: 180px;
                            }
                            td:nth-child(2){
                                width: 300px;
                            }
                            td:nth-child(3){
                                width: 120px;
                            }
                            td:nth-child(4){
                                width: 90px;
                            }
                            td:nth-child(5){
                                width: 120px;
                            }
                            td:nth-child(6){
                                width: 90px;
                            }
                            td:last-child{
                                border-right: 20px;
                            }
                        }
                        tr.selected_row{
                            background-color: $table-thead-bg;
                            td{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }

        @media(max-width: 575px){
            
        }
    }
}