@import '../../../../assets/scss/variables';
.import_rental_overlay_holder{
    .MuiPaper-root{
        width: 65%;
        background-color: $overlay-content-bg;
        .btn_circle_close{
            position: fixed;
            top: 25px;
            left: calc(35% - 61px);
            z-index: 1;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            color: $white-color;
            background-color: $primary-color;
            &:hover{
                color: $white-color;
                background-color: $primary-color;
            }
        }
        .d_header{
            background-color: $white-color;
            height: 70px;
            border-bottom: 1px solid $default-border;
            display: flex;
            justify-content: space-between;
            padding: 10px 30px;
            align-items: center;
            z-index: 1;
            .title{
                font-weight: 700;
                color: $title-color;
                font-size: 21px;
            }
            .btn_holder{
                display: flex;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;
                .btn_mui_icon_outline {
                    height: 34px;
                    font-weight: 700;
                    padding: 5px;
                    min-width: 34px;
                    font-size: 14px;
                    border-color: $default-border;
                    color: $nav-color;
                    &:hover{
                        color: $primary-color;
                    }

                }
            }
        }
        .d_content{
            padding: 30px 30px 100px;
            background-color: $overlay-content-bg;
            position: absolute;
            left: 0;
            right: 0;
            top: 70px;
            bottom: 0;
            overflow-y: auto;
            .url_input_holder{
                margin-bottom: 20px;
                font-weight: 600;
                position: relative;
                label{
                    font-size: 14px;
                    font-weight: 700;
                    color: $title-color;
                    margin: 0 0 5px;
                    display: block;
                }
                .input_flx_box{
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    .input_box{
                        width: 100%;
                    }
                    .btn_holder{
                        .btn_mui_dflt_outline{
                            white-space: nowrap;
                            height: 37.13px;
                            box-shadow: none;
                        }
                    }
                    @media(max-width: 575px){
                        flex-wrap: wrap;
                        gap: 10px;
                    }
                }
            }
            .container-box{
                border: 1px solid $default-border;
                border-radius: 5px;
                background-color: $white-color;
                box-shadow: 0 -2px 4px 0 $default-shadow;
                padding: 20px;
                .rental_successfully_added{
                    text-align: center;
                    padding: 50px 15px;
                    .success_icon_holder{
                        color: $green-color;
                        margin: 0 0 20px;
                        i{
                            font-size: 60px;
                        }
                    }
                    h4{
                        font-size: 21px;
                        font-weight: 700;
                        color: $title-color;
                        margin: 0 0 5px;
                    }
                    p{
                        font-size: 14px;
                        margin: 0 0 45px;
                    }
                    .btn_mui_primary{
                        min-width: 75px;
                    }
                }
                .importing_rental{
                    text-align: center;
                    padding: 50px 15px;
                    h4{
                        font-size: 21px;
                        font-weight: 700;
                        color: $title-color;
                        margin: 0 0 5px;
                    }
                    p{
                        font-size: 14px;
                        margin: 0 0 45px;
                    }
                    .progressbar_holder{
                        margin: 30px 0 75px;
                        .progressbar{
                            width: 200px !important;
                            height: 200px !important;
                            z-index: 1;
                            transform: rotate(210deg) !important;
                            .MuiCircularProgress-circle{
                                stroke-width: 1.6;
                            }
                        }
                        .progress_text{
                            position: absolute;
                            left: 7.5px;
                            right: 7.5px;
                            top: 7.5px;
                            bottom: 7.5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $white-color;
                            border-radius: 50%;
                            border: 1px solid $default-border;
                            box-shadow: inset 0 16px 117px 0 rgba(0,0,0,0), 0 -3px 10px 0 $white-color, 0 -16px 42px 0 rgba(219,219,219,0.83);
                        }
                        h4{
                            margin-top: 15px;
                            span{
                                font-size: 16px;
                                color: $chip-btn-color;
                            }
                        }
                    }
                }
            }
        }
        @media(max-width: 1449px){
            width: 75%;
            .btn_circle_close{
                left: calc(25% - 61px);
            }
        }
        @media(max-width: 1199px){
            width: 85%;
            .btn_circle_close{
                left: calc(15% - 61px);
            }
        }
        @media(max-width: 575px){
            width: 80%;
            .btn_circle_close{
                top: 20px;
                left: calc(20% - 51px);
            }
            .d_header{
                padding: 10px 20px;
                height: auto;
                flex-wrap: wrap;
                .title{
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .d_content{
                padding: 30px 20px 50px;
                .container-box{
                    padding: 15px;
                    .rental_successfully_added{
                        padding: 30px 0;
                        .success_icon_holder{
                            margin: 0 0 15px;
                        }
                        h4{
                            font-size: 18px;
                        }
                    }
                    .importing_rental{
                        padding: 30px 0;
                        h4{
                            font-size: 18px;
                        }
                        .progressbar_holder{
                            margin: 15px 0 50px;
                            .progressbar{
                                width: 150px !important;
                                height: 150px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}